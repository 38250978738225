import React, { useState } from "react";
import logo from "../assets/logo.png";
import axios from "axios";
import { API_BASE_URL } from "../constants/api-constants";
import { useNavigate } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import useMobile from "../hooks/useMobile";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const inputStyle: React.CSSProperties = {
    fontFamily: "muli-regular",
    border: "none",
    backgroundColor: "rgb(241, 243, 247)",
    padding: "6px 12px",
    borderRadius: "3px",
    width: "250px",
    height: "36px"
}

const Login = () => {

    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [remember, setRemember] = useState<boolean>(false);

    const [passwordView, setPasswordView] = useState<boolean>(false);

    const [validated, setValidated] = useState(true);

    const navigate = useNavigate();

    const mobile = useMobile();

    const [loading, setLoading] = useState(false);

    const onLogin = () => {
        setLoading(true);

        const data = {
            "username": username,
            "password": password
        }

        const url = `${API_BASE_URL}/auth/login`

        axios.post(url, data)
            .then(res => {
                setLoading(false);
                remember ? localStorage.setItem("GISToken", res.data.token) : sessionStorage.setItem("GISToken", res.data.token);
                navigate(window.location.href = "/dashboard");
                setValidated(true);
            })
            .catch(err => {
                setLoading(false);
                setValidated(false);
            })
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        onLogin();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(false)
        }
        setValidated(true);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "white", width: mobile ? "auto" : "450px", height: mobile ? "auto" : "500px", margin: mobile ? "80px 10px" : "70px auto", padding: mobile ? "30px 10px" : "30px 65px", border: "1px solid lightgray" }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onLogin();
                    e.preventDefault();
                }
            }}>
            <div className="logoTitle" style={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
                <img src={logo} alt="Logo" width="120px" height="100px" />
                <span style={{ fontWeight: "500", fontSize: "18px", marginTop: "5px", textAlign: "center" }}>Chautara SangachokGadhi Municipality</span>
                <span style={{ fontWeight: "500", fontSize: "18px", color: "#17a2b8" }}>Bagmati Province</span>
            </div>

            <div className="login" style={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center", marginTop: "15px", position: "relative" }}>
                <h3 style={{ marginBottom: "5px" }}>Login</h3>
                <Form noValidate onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <Form.Group>
                        <Form.Control disabled={loading ? true : false} type="text" isInvalid={validated ? false : true} placeholder="Username" style={inputStyle} onChange={e => setUsername(e.target.value)} />
                        <Form.Control.Feedback type="invalid" style={{ fontSize: "12px", marginBottom: "-5px" }}>
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control disabled={loading ? true : false} type={passwordView ? "text" : "password"} isInvalid={validated ? false : true} placeholder="Password" style={inputStyle} onChange={e => setPassword(e.target.value)} />
                        <Form.Control.Feedback type="invalid" style={{ fontSize: "12px", marginBottom: "-10px" }}>
                            Please enter valid credentials.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px", width: "100%", fontFamily: "muli-regular" }}>
                        <input type="checkbox" onChange={() => remember === false ? setRemember(true) : setRemember(false)} />Remember Me
                    </div>
                    <button style={{ width: "100%", background: "#234a83", height: "42px", border: "none", borderRadius: "5px", color: "white" }} type="submit">
                        {loading ?
                            <Spinner size="sm" animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            :
                            "Login"
                        }
                    </button>
                </Form>
                <div onClick={() => { passwordView ? setPasswordView(false) : setPasswordView(true) }} style={{ position: "absolute", right: validated ? "10px" : "30px", bottom: validated ? "102px" : "113px", cursor: "pointer" }}>
                    {!passwordView ?
                        <IoEyeOutline style={{ color: "#55595d" }} />
                        :
                        <IoEyeOffOutline style={{ color: "#55595d" }} />
                    }
                </div>
            </div>
        </div>
    )
}

export default Login;
