//@ts-nocheck
import React, { useState, useEffect, useContext, useRef } from "react";
import "./InfoPanel.css";
import axios from "axios";
import { Button, Modal, Form, Offcanvas, Spinner } from "react-bootstrap";
import { IoCloseOutline, IoAddOutline, IoInformationCircleOutline, IoCreateOutline, IoCloseCircleOutline, IoTrashOutline } from "react-icons/io5";
import useAdmin from "../hooks/useAdmin";
import { API_BASE_URL, API_IMAGE_URL } from "../constants/api-constants";
import { token } from "../constants/token";
import { InfoPanelContext, RefreshContext } from "../App";
import noImage from "../assets/noImage.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMobile from "../hooks/useMobile";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

type InfoPanelProps = {
  info: any;
  show: boolean;
  okAction?: () => Promise<void>;
};

const InfoPanel = ({ info, show }: InfoPanelProps) => {
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState(null);

  const mobile = useMobile();

  const [loading, setLoading] = useState(false);

  const success = () => toast.success('Success!')
  const failed = () => toast.error("Error!");

  const { refresh, setRefresh } = useContext(RefreshContext);

  const toggleRefresh = () => {
    refresh === true ? setRefresh(false) : setRefresh(true);
  }

  const admin = useAdmin();

  const [objectId, setObjectId] = useState();

  const { infoPanel, setInfoPanel } = useContext(InfoPanelContext);

  useEffect(() => {
    setInfoPanel();
  }, [refresh])

  const [section, setSection] = useState();

  useEffect(() => {
    setSection(info?.layer.id);
    setObjectId(info?.object?.id);
  }, [info])

  const [polygon, setPolygon] = useState(false);
  const [polygonId, setPolygonId] = useState();
  const [polygonObjectId, setPolygonObjectId] = useState();

  useEffect(() => {
    if (info !== null) {
      setExpand(true);
      if (info.object.geometry.type === "Polygon" || info.object.geometry.type === "LineString" || info.object.geometry.type === "MultiPolygon" || info.object.geometry.type === "MultiLineString") {
        setPolygon(true);

        const config = {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        };

        const url = info.layer.id === "agriculture" ? `${API_BASE_URL}/gis/agriculture/${info.object.object_id}`
          : info.layer.id === "urban-greenery" ? `${API_BASE_URL}/gis/forest/${info.object.object_id}`
            : info.layer.id === "open-space" ? `${API_BASE_URL}/gis/open_space/${info.object.object_id}`
              : info.layer.id === "local-road" ? `${API_BASE_URL}/gis/road_network_local_road/${info.object.object_id}`
                : info.layer.id === "highway" ? `${API_BASE_URL}/gis/road_network_highway/${info.object.object_id}`
                  : info.layer.id === "water-bodies" ? `${API_BASE_URL}/gis/water_bodies/${info.object.object_id}`
                    : info.layer.id === "economic-connectivity" ? `${API_BASE_URL}/gis/economic_connectivity/${info.object.object_id}`
                      : info.layer.id === "building-typology" ? `${API_BASE_URL}/gis/built_up_area/${info.object.object_id}`
                        : info.layer.id === "geology" ? `${API_BASE_URL}/gis/geology/${info.object.object_id}`
                          : info.layer.id === "land-form" ? `${API_BASE_URL}/gis/land_form_soil/${info.object.object_id}`
                            : ""

        axios.get(url, config)
          .then(res => {
            const newObject =
              info.layer.id === "geology" ?
                {
                  zone: res.data.zone,
                  group: res.data.group,
                  sub_group: res.data.sub_group,
                  rocktypes: res.data.rocktypes,
                  ward_no: res.data.ward_no,
                  images: res.data.images
                }
                :
                {
                  name: res.data.name,
                  district: res.data.district,
                  ward_no: res.data.ward_no,
                  mp_rmp: res.data.mp_rmp,
                  area_ha: res.data.area_ha,
                  images: res.data.images
                }
            const tableObject =
              info.layer.id === "geology" ?
                {
                  zone: res.data.zone,
                  group: res.data.group,
                  sub_group: res.data.sub_group,
                  rocktypes: res.data.rocktypes,
                  ward_no: res.data.ward_no,
                  ...res.data,
                  ...res.data.attributes
                }
                :
                {
                  name: res.data.name,
                  district: res.data.district,
                  ward_no: res.data.ward_no,
                  mp_rmp: res.data.mp_rmp,
                  area_ha: res.data.area_ha,
                  ...res.data,
                  ...res.data.attributes
                }
            setTableData(tableObject);
            setData(newObject);
            setPolygonId(res.data.id);
            setPolygonObjectId(res.data.object_id);
          })
          .catch(err => failed())
      } else {
        const newObject = {
          name: info.object.name,
          district: info.object.district,
          ward_no: info.object.ward_no,
          mp_rmp: info.object.mp_rmp,
          area_ha: info.object.area_ha,
          images: info.object.images
        }
        const tableObject = {
          name: info.object.name,
          district: info.object.district,
          ward_no: info.object.ward_no,
          mp_rmp: info.object.mp_rmp,
          area_ha: info.object.area_ha,
          coordinates: info.object.coordinats,
          ...info.object,
          ...info.object.attributes
        }
        setTableData(tableObject);
        setData(newObject);
      }
    } else if (infoPanel !== undefined) {
      setExpand(true);
      if (polygon === false) {
        const splitCoordinates = infoPanel !== undefined && infoPanel.data.geometry.split(",")
        const newObject = {
          name: infoPanel.data.name,
          // coordinates: [splitCoordinates[0], splitCoordinates[1]],
          district: infoPanel.data.district,
          ward_no: infoPanel.data.ward_no,
          mp_rmp: infoPanel.data.mp_rmp,
          area_ha: infoPanel.data.area_ha,
          images: infoPanel.data.images
        }
        const tableObject = {
          name: infoPanel.data.name,
          district: infoPanel.data.district,
          ward_no: infoPanel.data.ward_no,
          mp_rmp: infoPanel.data.mp_rmp,
          area_ha: infoPanel.data.area_ha,
          coordinates: [splitCoordinates[0], splitCoordinates[1]],
          ...infoPanel.data,
          ...infoPanel.data.attributes
        }
        setTableData(tableObject);
        setSection(sessionStorage.getItem("GISSection"));
        setObjectId(infoPanel.data.id);
        setData(newObject);
      } else {
        const newObject =
          section === "geology" ?
            {
              zone: infoPanel.data.zone,
              group: infoPanel.data.group,
              sub_group: infoPanel.data.sub_group,
              rocktypes: infoPanel.data.rocktypes,
              ward_no: infoPanel.data.ward_no,
              images: infoPanel.data.images
            }
            :
            {
              name: infoPanel.data.name,
              district: infoPanel.data.district,
              ward_no: infoPanel.data.ward_no,
              mp_rmp: infoPanel.data.mp_rmp,
              area_ha: infoPanel.data.area_ha,
              images: infoPanel.data.images
            }
        const tableObject =
          section === "geology" ?
            {
              zone: infoPanel.data.zone,
              group: infoPanel.data.group,
              sub_group: infoPanel.data.sub_group,
              rocktypes: infoPanel.data.rocktypes,
              ward_no: infoPanel.data.ward_no,
              ...infoPanel.data,
              ...infoPanel.data.attributes
            }
            :
            {
              name: infoPanel.data.name,
              district: infoPanel.data.district,
              ward_no: infoPanel.data.ward_no,
              mp_rmp: infoPanel.data.mp_rmp,
              area_ha: infoPanel.data.area_ha,
              ...infoPanel.data,
              ...infoPanel.data.attributes
            }
        setTableData(tableObject);
        setSection(sessionStorage.getItem("GISSection"));
        setObjectId(infoPanel.data.id);
        setData(newObject);
        setPolygonObjectId(infoPanel.data.object_id);
      }
    } else {
      setExpand(false);
    }
  }, [info, refresh, infoPanel]);

  const [attributeToAdd, setAttributeToAdd] = useState();
  const [valueToAdd, setValueToAdd] = useState();

  const [attributeToEdit, setAttributeToEdit] = useState<string>();
  const [valueToEdit, setValueToEdit] = useState<string>();

  const handleEditAttribute = (id: number) => {
    setLoading(true);

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };

    const data = attributeToEdit === "coordinates"
      ?
      {
        "geometry": valueToEdit
      }
      :
      {
        [attributeToEdit]: valueToEdit
      }

    sessionStorage.setItem("GISSection", section);

    const url = section === "cultural_point" ? `${API_BASE_URL}/gis/cultural_point/${id}`
      : section === "education_point" ? `${API_BASE_URL}/gis/educaiton_point/${id}`
        : section === "health_point" ? `${API_BASE_URL}/gis/health_service_point/${id}`
          : section === "agriculture" ? `${API_BASE_URL}/gis/agriculture/${polygonId}`
            : section === "urban-greenery" ? `${API_BASE_URL}/gis/forest/${polygonId}`
              : section === "open-space" ? `${API_BASE_URL}/gis/open_space/${polygonId}`
                : section === "local-road" ? `${API_BASE_URL}/gis/road_network_local_road/${polygonId}`
                  : section === "highway" ? `${API_BASE_URL}/gis/road_network_highway/${polygonId}`
                    : section === "water-bodies" ? `${API_BASE_URL}/gis/water_bodies/${polygonId}`
                      : section === "economic-connectivity" ? `${API_BASE_URL}/gis/economic_connectivity/${polygonId}`
                        : section === "building-typology" ? `${API_BASE_URL}/gis/built_up_area/${polygonId}`
                          : section === "geology" ? `${API_BASE_URL}/gis/geology/${polygonId}`
                            : section === "land-form" ? `${API_BASE_URL}/gis/land_form_soil/${polygonId}`
                              : "";;

    const getUrl = section === "cultural_point" ? `${API_BASE_URL}/gis/cultural_point/${id}`
      : section === "education_point" ? `${API_BASE_URL}/gis/education_point/${id}`
        : section === "health_point" ? `${API_BASE_URL}/gis/health_service_point/${id}`
          : section === "agriculture" ? `${API_BASE_URL}/gis/agriculture/${polygonObjectId}`
            : section === "open-space" ? `${API_BASE_URL}/gis/open_space/${polygonObjectId}`
              : section === "urban-greenery" ? `${API_BASE_URL}/gis/forest/${polygonObjectId}`
                : section === "local-road" ? `${API_BASE_URL}/gis/road_network_local_road/${polygonObjectId}`
                  : section === "highway" ? `${API_BASE_URL}/gis/road_network_highway/${polygonObjectId}`
                    : section === "water-bodies" ? `${API_BASE_URL}/gis/water_bodies/${polygonObjectId}`
                      : section === "economic-connectivity" ? `${API_BASE_URL}/gis/economic_connectivity/${polygonObjectId}`
                        : section === "building-typology" ? `${API_BASE_URL}/gis/built_up_area/${polygonObjectId}`
                          : section === "geology" ? `${API_BASE_URL}/gis/geology/${polygonObjectId}`
                            : section === "land-form" ? `${API_BASE_URL}/gis/land_form_soil/${polygonObjectId}`
                              : "";;

    axios.put(url, data, config)
      .then((res) => {
        handleEditClose();
        // success();
        toggleRefresh();
        axios.get(getUrl, config)
          .then(res => {
            setLoading(false);
            setInfoPanel(res)
            setObjectId(res.data.id)
            setSection(sessionStorage.getItem("GISSection"))
          })
          .catch(err => {
            setLoading(false)
            failed()
          })
      })
      .catch(err => {
        setLoading(false);
        handleEditClose();
        failed();
      })
  }

  const buttonStyle: React.CSSProperties = {
    marginLeft: "5px",
    marginTop: "-5px",
    backgroundColor: "rgb(255,10,10,0)",
    padding: "8px",
    border: "1px ",
    borderRadius: "10px",
  };

  const [infoShow, setInfoShow] = useState(false);

  const [editShow, setEditShow] = useState(false);

  const handleEditClose = () => {
    setEditShow(false);
  }

  const handleEditShow = (attributeToEdit, defaultValue) => {
    setEditShow(true);
    setAttributeToEdit(attributeToEdit);
    setValueToEdit(defaultValue);
    localStorage.setItem("GIS_ID", info !== null ? info.object.id : objectId);
  }

  const [addShow, setAddShow] = useState(false);

  const handleAddClose = () => {
    setAddShow(false);
    setAttributeToAdd("");
    setValueToAdd("");
  }
  const handleAddShow = () => setAddShow(true);

  const closeInfoPanel = () => {
    setExpand(false);
  }

  const addAttribute = (id: number) => {
    setLoading(true);
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };

    const data = {
      "id": polygon ? polygonId : id,
      "attribute": attributeToAdd,
      "value": valueToAdd
    }

    sessionStorage.setItem("GISSection", section);

    const url = section === "cultural_point" ? `${API_BASE_URL}/gis/add_cultural_attribute`
      : section === "education_point" ? `${API_BASE_URL}/gis/add_education_attribute`
        : section === "health_point" ? `${API_BASE_URL}/gis/add_health_attribute`
          : section === "agriculture" ? `${API_BASE_URL}/gis/add_agriculture_attribute`
            : section === "urban-greenery" ? `${API_BASE_URL}/gis/add_forest_attribute`
              : section === "open-space" ? `${API_BASE_URL}/gis/add_open_space_attribute`
                : section === "local-road" ? `${API_BASE_URL}/gis/add_road_network_local_road_attribute`
                  : section === "highway" ? `${API_BASE_URL}/gis/add_road_network_highway_attribute`
                    : section === "water-bodies" ? `${API_BASE_URL}/gis/add_water_bodies_attribute`
                      : section === "economic-connectivity" ? `${API_BASE_URL}/gis/add_economic_connectivity_attribute`
                        : section === "building-typology" ? `${API_BASE_URL}/gis/add_built_up_area_attribute`
                          : section === "geology" ? `${API_BASE_URL}/gis/add_geology_attribute`
                            : section === "land-form" ? `${API_BASE_URL}/gis/add_land_form_soil_attribute`
                              : "";

    const getUrl = section === "cultural_point" ? `${API_BASE_URL}/gis/cultural_point/${id}`
      : section === "education_point" ? `${API_BASE_URL}/gis/education_point/${id}`
        : section === "health_point" ? `${API_BASE_URL}/gis/health_service_point/${id}`
          : section === "agriculture" ? `${API_BASE_URL}/gis/agriculture/${polygonObjectId}`
            : section === "open-space" ? `${API_BASE_URL}/gis/open_space/${polygonObjectId}`
              : section === "urban-greenery" ? `${API_BASE_URL}/gis/forest/${polygonObjectId}`
                : section === "local-road" ? `${API_BASE_URL}/gis/road_network_local_road/${polygonObjectId}`
                  : section === "highway" ? `${API_BASE_URL}/gis/road_network_highway/${polygonObjectId}`
                    : section === "water-bodies" ? `${API_BASE_URL}/gis/water_bodies/${polygonObjectId}`
                      : section === "economic-connectivity" ? `${API_BASE_URL}/gis/economic_connectivity/${polygonObjectId}`
                        : section === "building-typology" ? `${API_BASE_URL}/gis/built_up_area/${polygonObjectId}`
                          : section === "geology" ? `${API_BASE_URL}/gis/geology/${polygonObjectId}`
                            : section === "land-form" ? `${API_BASE_URL}/gis/land_form_soil/${polygonObjectId}`
                              : "";;

    axios.post(url, data, config)
      .then((res) => {
        handleAddClose();
        toggleRefresh();
        // success();
        axios.get(getUrl, config)
          .then(res => {
            setLoading(false);
            setInfoPanel(res)
            setObjectId(res.data.id)
            setSection(sessionStorage.getItem("GISSection"))
          })
          .catch(err => {
            setLoading(false);
            failed()
          })
      })
      .catch(err => {
        setLoading(false)
        handleAddClose();
        failed();
      })
  }

  const hiddenFileInput = useRef<any>();

  const handleAddImage = () => {
    hiddenFileInput.current.click();
  }

  const handleImageUpload = (id, images) => {

    setLoading(true);

    const url = section === "cultural_point" ? `${API_BASE_URL}/gis/cultural_image/${id}`
      : section === "education_point" ? `${API_BASE_URL}/gis/education_image/${id}`
        : section === "health_point" ? `${API_BASE_URL}/gis/health_image/${id}`
          : section === "agriculture" ? `${API_BASE_URL}/gis/agriculture_image/${polygonId}`
            : section === "urban-greenery" ? `${API_BASE_URL}/gis/forest_image/${polygonId}`
              : section === "open-space" ? `${API_BASE_URL}/gis/open_space_image/${polygonId}`
                : section === "local-road" ? `${API_BASE_URL}/gis/road_network_local_road_image/${polygonId}`
                  : section === "highway" ? `${API_BASE_URL}/gis/road_network_highway_image/${polygonId}`
                    : section === "water-bodies" ? `${API_BASE_URL}/gis/water_bodies_image/${polygonId}`
                      : section === "economic-connectivity" ? `${API_BASE_URL}/gis/economic_connectivity_image/${polygonId}`
                        : section === "building-typology" ? `${API_BASE_URL}/gis/built_up_area_image/${polygonId}`
                          : section === "geology" ? `${API_BASE_URL}/gis/geology_image/${polygonId}`
                            : section === "land-form" ? `${API_BASE_URL}/gis/land_form_soil_image/${polygonId}`
                              : "";

    const getUrl = section === "cultural_point" ? `${API_BASE_URL}/gis/cultural_point/${id}`
      : section === "education_point" ? `${API_BASE_URL}/gis/education_point/${id}`
        : section === "health_point" ? `${API_BASE_URL}/gis/health_service_point/${id}`
          : section === "agriculture" ? `${API_BASE_URL}/gis/agriculture/${polygonObjectId}`
            : section === "open-space" ? `${API_BASE_URL}/gis/open_space/${polygonObjectId}`
              : section === "urban-greenery" ? `${API_BASE_URL}/gis/forest/${polygonObjectId}`
                : section === "local-road" ? `${API_BASE_URL}/gis/road_network_local_road/${polygonObjectId}`
                  : section === "highway" ? `${API_BASE_URL}/gis/road_network_highway/${polygonObjectId}`
                    : section === "water-bodies" ? `${API_BASE_URL}/gis/water_bodies/${polygonObjectId}`
                      : section === "economic-connectivity" ? `${API_BASE_URL}/gis/economic_connectivity/${polygonObjectId}`
                        : section === "building-typology" ? `${API_BASE_URL}/gis/built_up_area/${polygonObjectId}`
                          : section === "geology" ? `${API_BASE_URL}/gis/geology/${polygonObjectId}`
                            : section === "land-form" ? `${API_BASE_URL}/gis/land_form_soil/${polygonObjectId}`
                              : "";;

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };

    sessionStorage.setItem("GISSection", section);

    if (images.length === 1) {
      const formData = new FormData();
      formData.append("image", images[0]);

      axios.post(url, formData, config)
        .then(res => {
          toggleRefresh();
          // success();
          axios.get(getUrl, config)
            .then(res => {
              setLoading(false)
              setInfoPanel(res)
              setObjectId(res.data.id)
              setSection(sessionStorage.getItem("GISSection"))
            })
            .catch(err => {
              setLoading(fasle)
              failed()
            })
        })
        .catch(err => {
          setLoading(false)
          failed()
        })
    } else {
      Array.from(images).map(image => {
        const formData = new FormData();
        formData.append("image", image);

        axios.post(url, formData, config)
          .then(res => {
            toggleRefresh();
            // success();
            axios.get(getUrl, config)
              .then(res => {
                setLoading(false);
                setInfoPanel(res)
                setObjectId(res.data.id)
                setSection(sessionStorage.getItem("GISSection"))
              })
              .catch(err => {
                setLoading(false)
                failed()
              })
          })
          .catch(err => {
            setLoading(false)
            failed()
          })
      })
    }
  }

  const handleInfoPanelClose = () => {
    setExpand(false);
    setInfoPanel();
  }

  const [carouselShow, setCarouselShow] = useState(false);

  const handleCarouselClose = () => setCarouselShow(false);
  const handleCarouselShow = () => setCarouselShow(true);

  const [editIndex, setEditIndex] = useState();

  const [imageToDelete, setImageToDelete] = useState();

  const handleImageDelete = (image) => {

    setLoading(true);

    setImageToDelete();

    const url = section === "cultural_point" ? `${API_BASE_URL}/gis/cultural_image/${image.id}`
      : section === "education_point" ? `${API_BASE_URL}/gis/education_image/${image.id}`
        : section === "health_point" ? `${API_BASE_URL}/gis/health_image/${image.id}`
          : section === "agriculture" ? `${API_BASE_URL}/gis/agriculture_image/${image.id}`
            : section === "urban-greenery" ? `${API_BASE_URL}/gis/forest_image/${image.id}`
              : section === "open-space" ? `${API_BASE_URL}/gis/open_space_image/${image.id}`
                : section === "local-road" ? `${API_BASE_URL}/gis/road_network_local_road_image/${image.id}`
                  : section === "highway" ? `${API_BASE_URL}/gis/road_network_highway_image/${image.id}`
                    : section === "water-bodies" ? `${API_BASE_URL}/gis/water_bodies_image/${image.id}`
                      : section === "economic-connectivity" ? `${API_BASE_URL}/gis/economic_connectivity_image/${image.id}`
                        : section === "building-typology" ? `${API_BASE_URL}/gis/built_up_area_image/${image.id}`
                          : section === "geology" ? `${API_BASE_URL}/gis/geology_image/${image.id}`
                            : section === "land-form" ? `${API_BASE_URL}/gis/land_form_soil_image/${image.id}`
                              : "";

    const getUrl = section === "cultural_point" ? `${API_BASE_URL}/gis/cultural_point/${objectId}`
      : section === "education_point" ? `${API_BASE_URL}/gis/education_point/${objectId}`
        : section === "health_point" ? `${API_BASE_URL}/gis/health_service_point/${objectId}`
          : section === "agriculture" ? `${API_BASE_URL}/gis/agriculture/${polygonObjectId}`
            : section === "open-space" ? `${API_BASE_URL}/gis/open_space/${polygonObjectId}`
              : section === "urban-greenery" ? `${API_BASE_URL}/gis/forest/${polygonObjectId}`
                : section === "local-road" ? `${API_BASE_URL}/gis/road_network_local_road/${polygonObjectId}`
                  : section === "highway" ? `${API_BASE_URL}/gis/road_network_highway/${polygonObjectId}`
                    : section === "water-bodies" ? `${API_BASE_URL}/gis/water_bodies/${polygonObjectId}`
                      : section === "economic-connectivity" ? `${API_BASE_URL}/gis/economic_connectivity/${polygonObjectId}`
                        : section === "building-typology" ? `${API_BASE_URL}/gis/built_up_area/${polygonObjectId}`
                          : section === "geology" ? `${API_BASE_URL}/gis/geology/${polygonObjectId}`
                            : section === "land-form" ? `${API_BASE_URL}/gis/land_form_soil/${polygonObjectId}`
                              : "";;

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };

    sessionStorage.setItem("GISSection", section);

    axios.delete(url, config)
      .then(res => {
        toggleRefresh();
        // success();
        axios.get(getUrl, config)
          .then(res => {
            setLoading(false);
            if (res.data.images.length === 0) {
              handleCarouselClose();
            };
            setInfoPanel(res)
            setObjectId(res.data.id)
            setSection(sessionStorage.getItem("GISSection"))
          })
          .catch(err => {
            setLoading(false)
            failed()
          })
      })
      .catch(err => {
        setLoading(false)
        failed()
      })
  }

  const infoTable = [
    tableData !== null && Object.keys(tableData).filter((field) => field !== "id" && field !== "geometry" && field !== "images" && field !== "attributes").map((field, index) => {
      return (
        {
          attribute: field,
          value: field === "coordinates" ? `[${tableData[field][0]}, ${tableData[field][1]}]` : tableData[field]
        }
      )
    })
  ]

  const columns: GridColDef[] = [
    {
      field: 'attribute',
      headerName: 'Attribute',
      editable: false,
      flex: 2,
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: false,
      flex: 6,
      renderCell: (params) => {
        if (params.row.value !== null) {
          return params.row.value
        } else {
          return "-"
        }
      }
    },
    admin &&
    {
      field: 'Edit',
      headerName: 'Edit',
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <button style={buttonStyle} onClick={() => handleEditShow(params.row.attribute, params.row.value)} >
            <IoCreateOutline style={{ width: "14px", height: "14px", color: "#555956" }} />
          </button>
        )
      }
    }
  ];

  return (
    <>
      <Offcanvas show={expand} placement={mobile ? "bottom" : "end"} onHide={handleInfoPanelClose} backdrop={false} scroll={true}
        style={{
          width: mobile ? "100%" : "275px",
          height: mobile ? "80vh" : "100%"
        }}>
        <div className="info-panel-header"
          style={{
            width: mobile ? "100%" : "275px",
            borderTop: mobile ? "1px solid lightgray" : "none"
          }}>
          <div style={{ position: "sticky", top: "0", backgroundColor: "white" }}>
            <div className="info-header">
              <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                <IoInformationCircleOutline style={{ width: "15px", height: "15px" }} />
                <h6 style={{ margin: "0px" }}>Info</h6>
              </div>
              {admin ?
                <div className="panel-icons">
                  <div onClick={handleAddShow} style={{ display: "flex", alignItems: "center", fontWeight: "500", cursor: "pointer", marginTop: "6px", color: "white", backgroundColor: "#106eea", padding: "0px 8px 0px 2px", borderRadius: "5px" }}>
                    <IoAddOutline style={{ width: "15px", height: "15px", marginBottom: "2px" }} />Add Attribute
                  </div>
                  <IoCloseOutline onClick={closeInfoPanel} style={{ width: "18px", height: "18px", cursor: "pointer", marginTop: "6px", color: "#555956" }} />
                </div>
                :
                <div className="panel-icons">
                  <IoCloseOutline onClick={closeInfoPanel} style={{ width: "18px", height: "18px", cursor: "pointer", marginTop: "6px", color: "#555956" }} />
                </div>
              }
            </div>
            <hr style={{ margin: "0px" }} />
          </div>
        </div>

        <div className="info-panel-body"
          style={{ width: mobile ? "100%" : "275px", height: "100%" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", paddingTop: "25px" }}>
            {(data?.images?.length === 0 && admin || data?.images === undefined && admin)
              ?
              <div style={{ display: "flex" }}>
                <div onClick={handleAddImage} style={{ display: "flex", alignItems: "center", fontWeight: "500", cursor: "pointer", color: "white", backgroundColor: "#106eea", padding: "0px 8px 0px 2px", borderRadius: "5px" }}>
                  <IoAddOutline style={{ width: "15px", height: "15px", marginBottom: "2px" }} />Add Image
                  <input
                    type="file"
                    multiple
                    ref={hiddenFileInput}
                    accept='image/*'
                    onChange={(event) => {
                      handleImageUpload(objectId, event.target.files);
                    }}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              : (data?.images?.length === 0 && !admin || data?.images === undefined && !admin)
                ? <img src={noImage} width="275px" height="125px" style={{ objectFit: "contain" }} />
                : data?.images?.length > 0 && admin
                  ?
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                    < img src={`${API_IMAGE_URL}${data.images[0].path}`} alt="Image" width="235px" height="100px" onClick={handleCarouselShow} style={{ objectFit: "contain", cursor: "pointer" }} />
                    <div onClick={handleAddImage} style={{ display: "flex", alignItems: "center", fontWeight: "500", cursor: "pointer", color: "white", backgroundColor: "#106eea", padding: "0px 8px 0px 2px", borderRadius: "5px" }}>
                      <IoAddOutline style={{ width: "15px", height: "15px", marginBottom: "2px" }} />Add Image
                      <input
                        type="file"
                        multiple
                        ref={hiddenFileInput}
                        accept='image/*'
                        onChange={(event) => {
                          handleImageUpload(objectId, event.target.files);
                        }}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  :
                  < img src={`${API_IMAGE_URL}${data.images[0].path}`} alt="Image" width="235px" height="100px" onClick={handleCarouselShow} style={{ objectFit: "contain", cursor: "pointer" }} />
            }
          </div>

          <div className="info-content">
            <div>
              {
                data !== null && Object.keys(data).filter((field) => field !== "id" && field !== "geometry" && field !== "images" && field !== "attributes").map((field, index) => {
                  return (
                    <div className="attribute" onMouseEnter={() => setEditIndex(index)} onMouseLeave={() => setEditIndex(null)} key={field} style={{ display: "flex", gap: "5px" }}>
                      <p style={{ display: "flex", margin: "0px" }}>
                        {/* <strong>{field.toUpperCase()}:</strong>{" "} */}
                        <div style={{ width: "90px" }}>
                          <div
                            style={{
                              height: "23px",
                              padding: "0px 10px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              backgroundColor: "#e0e0e0",
                              marginLeft: "3px",
                              width: "auto",
                              display: "inline-block",
                              marginBottom: "5px"
                            }}>
                            <strong>{field.toUpperCase()}</strong>
                          </div>
                        </div>

                        <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "105px" }}>
                          {field === "coordinates" ?
                            data[field] !== null && data[field] !== undefined ? `[${data[field][0]}, ${data[field][1]}]` : "-"
                            : data[field] ? data[field] : "---"
                          }
                        </div>
                      </p>
                      {admin && index === editIndex ?
                        <button style={buttonStyle} onClick={() => handleEditShow(field, data[field])} >
                          <IoCreateOutline style={{ width: "14px", height: "14px", color: "#555956" }} />
                        </button>
                        :
                        <button style={buttonStyle} disabled >
                          <IoCreateOutline style={{ width: "15px", height: "16px", color: "white" }} />
                        </button>
                      }
                    </div>
                  )
                })
              }
            </div>
            <span onClick={() => setInfoShow(true)} style={{ color: "blue", cursor: "pointer", textDecoration: "underline", marginLeft: "5px" }}>Show more</span>
          </div>
        </div >
      </Offcanvas >

      <Modal show={infoShow} size="lg" onHide={() => setInfoShow(false)} centered style={{ height: "650px" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <IoInformationCircleOutline style={{ width: "20px", height: "20px", marginTop: "-1px", marginRight: "5px" }} />
            Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataGrid
            rows={infoTable[0]}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
            getRowId={(row) => row.attribute}
            disableColumnMenu
            getRowHeight={() => "auto"}
            sx={{
              height: "461px",
              "& .MuiDataGrid-cellContent": {
                minHeight: 30,
              }
            }}
          />
        </Modal.Body>

      </Modal>

      <Modal show={editShow} onHide={loading ? () => { return null } : handleEditClose} centered>
        <Modal.Header closeButton={loading ? false : true}>
          <Modal.Title>Edit {attributeToEdit !== undefined && attributeToEdit.toUpperCase()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Enter Value:</Form.Label>
            <Form.Control disabled={loading ? true : false} type="text" value={valueToEdit} onChange={e => setValueToEdit(e.target.value)} />
            <div onClick={loading ? () => { return null } : () => setValueToEdit("")} style={{ position: "absolute", top: "55px", right: "22px", cursor: "pointer" }}>
              <IoCloseCircleOutline style={{ width: "22px", height: "22px", color: "#555956" }} />
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" style={{ width: "60px" }} onClick={() => handleEditAttribute(objectId)}>
            {loading ?
              <Spinner size="sm" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              :
              "Save"
            }
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={addShow} onHide={loading ? () => { return null } : handleAddClose} centered>
        <Modal.Header closeButton={loading ? false : true}>
          <Modal.Title>Add Attribute</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Enter Attribute:</Form.Label>
            <Form.Control disabled={loading ? true : false} type="text" onChange={e => setAttributeToAdd(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Enter Value:</Form.Label>
            <Form.Control disabled={loading ? true : false} type="text" onChange={e => setValueToAdd(e.target.value)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading ? true : false} variant="secondary" onClick={handleAddClose}>
            Close
          </Button>
          <Button variant="primary" style={{ width: "60px" }} onClick={() => addAttribute(objectId)}>
            {loading ?
              <Spinner size="sm" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              :
              "Add"
            }
          </Button>
        </Modal.Footer>
      </Modal >

      <Modal show={carouselShow} onHide={handleCarouselClose} size="lg" centered>
        <Modal.Body>
          <div style={{ marginBottom: "-30px" }}>
            <Carousel dynamicHeight showThumbs={true}>
              {
                data?.images?.map(image => {
                  return (
                    <div>
                      <img src={`${API_IMAGE_URL}${image.path}`} alt="Image" width="100%" height="100%" style={{ maxWidth: "700px", maxHeight: "350px", objectFit: "contain" }} />
                      {admin && <IoTrashOutline onClick={() => setImageToDelete(image)} style={{ width: "22px", height: "22px", color: "#7f7f7f", cursor: "pointer", position: "absolute", top: "5px", right: "30px" }} />}
                    </div>
                  )
                })
              }
            </Carousel>
          </div>
        </Modal.Body>
      </Modal >

      <Modal show={imageToDelete !== undefined} onHide={() => setImageToDelete()} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this image?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setImageToDelete()}>
            No
          </Button>
          <Button variant="primary" onClick={() => handleImageDelete(imageToDelete)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={1}
      />
    </>
  );
};

export default React.memo(InfoPanel);
