import Navbar from "../components/NavBar";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { token } from "../constants/token";
import { API_BASE_URL } from "../constants/api-constants";
import { RefreshContext } from "../App";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMobile from "../hooks/useMobile";
import { IoTrashOutline, IoCreateOutline } from "react-icons/io5";
import { Button, Form, Modal } from "react-bootstrap";

export type UserType = {
    id: number,
    username: string,
    email: string,
    password: string,
    role: string
}

const Dashboard = () => {

    const mobile = useMobile();

    const [validated, setValidated] = useState(true);
    const [error, setError] = useState();

    const success = () => toast.success('Success!')
    const failed = () => toast.error("Error!");

    const { refresh, setRefresh } = useContext(RefreshContext);

    const toggleRefresh = () => {
        refresh ? setRefresh(false) : setRefresh(true);
    }

    const [users, setUsers] = useState<UserType | any>();

    const [userToDelete, setUserToDelete] = useState();
    const [emailToEdit, setEmailToEdit] = useState();
    const [newEmail, setNewEmail] = useState<string>();

    const handleUserDelete = (user: number | undefined) => {
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        const url = `${API_BASE_URL}/auth/delete-user/${userToDelete}`

        axios.delete(url, config)
            .then(res => {
                toggleRefresh();
                success();
                setUserToDelete(undefined);
            })
            .catch(err => {
                failed();
                setUserToDelete(undefined);
            })
    }

    const handleEmailEdit = (user: number | undefined, e: any) => {
        e.preventDefault();

        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        const url = `${API_BASE_URL}/auth/update-user-email/${emailToEdit}`

        const data = {
            "email": newEmail
        }

        axios.put(url, data, config)
            .then(res => {
                toggleRefresh();
                success();
                setEmailToEdit(undefined);
            })
            .catch(err => {
                setError(err.response.data.message);
                // failed();
                // setEmailToEdit(undefined);
                setValidated(false);
            })
    }

    useEffect(() => {
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        const url = `${API_BASE_URL}/auth/users`

        axios.get(url, config)
            .then(res => setUsers(res.data))
            .catch(err => failed())
    }, [refresh])

    const columns: GridColDef[] = [
        {
            field: 'sn',
            headerName: 'SN',
            headerAlign: 'center',
            editable: false,
            sortable: false,
            align: 'center',
            flex: 1,
            renderCell: (params) => params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
        },
        {
            field: 'username',
            headerName: 'Username',
            headerAlign: 'center',
            editable: false,
            sortable: false,
            align: 'center',
            flex: 3
        },
        {
            field: 'email',
            headerName: 'E-mail',
            headerAlign: 'center',
            editable: false,
            sortable: false,
            align: 'center',
            flex: 4
        },
        {
            field: 'password',
            headerName: 'Password',
            sortable: false,
            headerAlign: 'center',
            editable: false,
            align: 'center',
            flex: 6
        },
        {
            field: 'role',
            headerName: 'Role',
            sortable: false,
            headerAlign: 'center',
            editable: false,
            align: 'center',
            flex: 2
        },
        {
            field: 'actioins',
            headerName: 'Actions',
            sortable: false,
            headerAlign: 'center',
            editable: false,
            align: 'center',
            flex: 2,
            renderCell: (row) => {
                return (
                    <div style={{ display: "flex", gap: "5px" }}>
                        <IoCreateOutline
                            onClick={() => {
                                setValidated(true)
                                setEmailToEdit(row.row.id)
                            }}
                            style={{
                                width: "21px",
                                height: "21px",
                                cursor: "pointer"
                            }} />
                        <IoTrashOutline onClick={() => setUserToDelete(row.row.id)} style={{ width: "21px", height: "21px", color: "red", cursor: "pointer" }} />
                    </div>
                )
            }
        },
    ];

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: "100px" }}>
                <div>
                    <Navbar />
                </div>

                <div style={{ margin: mobile ? "10px 10px 0px 10px" : "0px 50px" }}>
                    <div style={{ height: mobile ? "80vh" : "80vh", width: '100%', background: "white" }}>
                        <DataGrid
                            rows={users !== undefined ? users : ""}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            disableRowSelectionOnClick
                            getRowId={(row) => row.id}
                            disableColumnMenu
                        />
                    </div>
                </div>
            </div >

            <Modal show={userToDelete !== undefined} onHide={() => setUserToDelete(undefined)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this user?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setUserToDelete(undefined)}>
                        No
                    </Button>
                    <Button variant="primary" onClick={() => handleUserDelete(userToDelete)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={emailToEdit !== undefined} onHide={() => setEmailToEdit(undefined)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit E-mail</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={(e) => handleEmailEdit(emailToEdit, e)}>
                    <Modal.Body>
                        <Form.Label>Enter new e-mail:</Form.Label>
                        <Form.Control type="text" isInvalid={!validated} onChange={e => setNewEmail(e.target.value)} />
                        <Form.Control.Feedback type="invalid" style={{ fontSize: "12px", marginBottom: "-5px" }}>
                            {error === "Email already exists" ? "An user with this e-mail already exists." : "Please enter a valid e-mail."}
                        </Form.Control.Feedback>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setEmailToEdit(undefined)}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                limit={1}
            />
        </>
    )
}

export default Dashboard
