// export const API_BASE_URL = "http://192.168.1.82:3300/api/v1";
// export const API_IMAGE_URL = "http://192.168.1.82:3300/api/v1";

// export const API_BASE_URL = "https://madan.serveo.net/api/v1";
// export const API_IMAGE_URL = "https://madan.serveo.net";

// export const API_BASE_URL = "https://gis-api.scieverinc.com";
// export const API_IMAGE_URL = "https://gis-api.scieverinc.com/";

export const API_BASE_URL = "https://gischautara.dryicesolutions.net/apiapi/v1";
export const API_IMAGE_URL = "https://gischautara.dryicesolutions.net/api";


