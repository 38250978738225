import DeckGL from "@deck.gl/react/typed";
import { useMemo } from "react";
import { TileLayer } from "@deck.gl/geo-layers/typed";
import {
  BitmapBoundingBox,
  BitmapLayer,
  IconLayerProps,
  PathLayer,
  GeoJsonLayer
} from "@deck.gl/layers/typed";

const INITIAL_VIEW_STATE = {
  longitude: 85.71490102721086,
  latitude: 27.75991528556676,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};

const ICON_MAPPING: IconLayerProps["iconMapping"] = {
  cultural_point: { x: 0, y: 0, width: 400, height: 350, mask: true },
};

const Road = () => {
  const layers = useMemo(
    () => [
      new TileLayer({
        // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers
        data: "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        renderSubLayers: (props) => {
          const { boundingBox } = props.tile;
          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: boundingBox.flatMap((x) => x) as BitmapBoundingBox,
          });
        },
      }),

      // new PathLayer({
      //   id: "path-layer",
      //   data: road,
      //   widthMinPixels: 1,
      //   highlightColor: [128, 128, 128, 256],
      //   autoHighlight: true,
      //   capRounded: true,
      //   jointRounded: true,
      //   billboard: true,
      //   getWidth: 0,
      //   getPath: (d) => d.coordinates[0],
      //   getColor: [245, 39, 58, 255],
      //   pickable: true
      // })

      // new GeoJsonLayer({
      //   id: 'geojson-layer',
      //   data: Road2,
      //   pickable: true,
      //   stroked: false,
      //   filled: true,
      //   extruded: true,
      //   pointType: 'circle',
      //   lineWidthScale: 1,
      //   lineWidthMinPixels: 2,
      //   getFillColor: [160, 160, 180, 200],
      //   // getLineColor: d => colorToRGBArray(d.properties.color),
      //   getPointRadius: 100,
      //   getLineWidth: 1,
      //   getElevation: 30,

      // })
    ],
    []
  );
  return (
    <>
      <DeckGL
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={layers}
        getTooltip={({ object }) => object && `${object.name}`}
      ></DeckGL>
    </>
  );
};

export default Road;
