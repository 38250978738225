import PublicService from "../assets/publicService.json";

export const publicService = PublicService.features.map((x: any) => ({
  coordinates: x.geometry.coordinates,
  name: x.properties.Name,
  type: x.properties.LEVEL2,
}));

// export const educational=
// export const hralth=
// export const utili=
// export const recreational=
// export const secur=
