import { useEffect, useState } from "react";
import { token } from "../constants/token";
import { API_BASE_URL } from "../constants/api-constants";
import axios from "axios";

export default function useAdmin() {

    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        const url = `${API_BASE_URL}/auth/me`

        axios.get(url, config)
            .then(res => {
                setUser(res.data);
            })
    }, [])

    return (
        (user !== null && user.error !== "Invalid token") && (user.user.role === "Admin")
            ? true
            : false
    )
}