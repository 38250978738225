//@ts-nocheck
import DeckGL from "@deck.gl/react/typed";
import { useContext, useEffect, useMemo, useState } from "react";
import { TileLayer } from "@deck.gl/geo-layers/typed";
import {
  BitmapBoundingBox,
  BitmapLayer,
  IconLayer,
  IconLayerProps,
} from "@deck.gl/layers/typed";
import building from "../assets/icons/home.png";
import axios from "axios";
import InfoPanel from "./InfoPanel";
import items from "../assets/data.json";
import Sidebar from "./SideBar";
import { token } from "../constants/token";
import { API_BASE_URL } from "../constants/api-constants";
import { RefreshContext } from "../App";

const INITIAL_VIEW_STATE = {
  longitude: 85.71490102721086,
  latitude: 27.75991528556676,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};

const ICON_MAPPING: IconLayerProps["iconMapping"] = {
  public_point: { x: 0, y: 0, width: 512, height: 512, mask: true },
};
interface CustomPopupProps {
  longitude: number;
  latitude: number;
  children: React.ReactNode;
  onClose: () => void;
}

export const CustomPopup: React.FC<CustomPopupProps> = ({
  position,
  children,
  onClose,
}) => {
  const data = children[0];
  const [name, setName] = useState(children[0].name);
  const [coordinates, setCoordinates] = useState(children[0].coordinates);
  const coordinatesString = Array.isArray(coordinates) ? `${coordinates.join(', ')}` : coordinates;

  useEffect(() => {
    setName(children[0].name);
    setCoordinates(children[0].coordinates);
  }, [children]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      const popup = document.getElementById('custom-popup');
      if (popup && !popup.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [onClose]);

  const handleEditClick = async (id: number, field: string) => {
    const editedValue = window.prompt(`Edit ${field}:`);
    if (editedValue) {
      try {
        const data = {
          id: id,
        };
        data[field] = editedValue;
        // console.log(data)
        axios.put("http://localhost:3300/gis/public_service_point", data);
        if (field === "name") {
          setName(editedValue);
        } else if (field === "coordinates") {
          setCoordinates(editedValue);
        }
        // console.log("PUT request sent");
      } catch (error) {
        // console.log("An error occurred while making the PUT request.");
      }
    }
  };

  const popupStyle: React.CSSProperties = {
    position: "absolute",
    left: 0,
    top: 0,
    transform: `translate(${children[1] - 170}px, ${children[2] - 75}px)`,
    backgroundColor: "white",
    padding: "5px",
    border: "1px solid darkgray",
    borderRadius: "5px",
    zIndex: 100,
    fontSize: "12px",
  };

  const arrow: React.CSSProperties = {
    position: "absolute",
    width: "12px",
    height: "12px",
    backgroundColor: "white",
    transform: "rotate(45deg)",
    bottom: "-7px",
    left: "48%",
    borderBottom: "1px solid gray",
    borderRight: "1px solid gray",
  };

  const buttonStyle: React.CSSProperties = {
    marginLeft: "5px",
    backgroundColor: "white",
    padding: "5px",
    border: "1px ",
    borderRadius: "10px",
    zIndex: 9999,
  };


  return (
    <div style={popupStyle}>
      <div style={arrow}></div>
      {Object.keys(data).map((field) => (
        <div key={field}>
          {field.toUpperCase()}:{" "}
          {data[field] !== null ? data[field] : "-"}
          <br />
        </div>
      ))}
    </div>
  );
};

const PublicServicePoint = () => {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [publicPoint, setPublicPoint] = useState([]);
  const data = selectedFeature;
  const [popupData, setPopupData] = useState(null);

  const { refresh, setRefresh } = useContext(RefreshContext);

  const reformattedData = []

  // Iterate through the original data
  publicPoint.forEach(item => {
    // Create a copy of the item
    const newItem = { ...item };

    // Remove the 'attributes' property from the new item
    delete newItem.attributes;

    // Merge the 'attributes' properties into the new item
    Object.assign(newItem, item.attributes);

    // Push the reformatted item to the new array
    reformattedData.push(newItem);
  });

  const config = {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  };

  const url = `${API_BASE_URL}/gis/public_service_point`

  const newData = [];

  useEffect(() => {
    axios.get(url, config)
      .then(res => {
        res.data.map(item => {
          const splitCoordinates = item.geometry.split(",");
          const coordinates = [parseFloat(splitCoordinates[0]), parseFloat(splitCoordinates[1])]
          item.coordinates = coordinates;
          newData.push(item);
        })
        setPublicPoint(newData);
      })
      // .catch(err => console.log(err))

  }, [refresh])

  const handleIconClick = (object: any) => {
    const newObject = {
      object: {
        name: object.object.name.toUpperCase(),
        coordinates: `[${object.object.coordinates[0]}, ${object.object.coordinates[1]}]`
      },
      x: object.x,
      y: object.y
    }
    setSelectedFeature(object);
    setPopupData(newObject);
  };

  const renderPopup = () => {
    if (popupData) {
      const { x, y, object } = popupData;

      return (
        <div id="custom-popup">
          <CustomPopup
            position={object.position}
            onClose={() => setPopupData(null)}
          >
            {object}
            {x}
            {y}
          </CustomPopup>
        </div>
      );
    }
    return null;
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const response = await axios.get(
  //         "http://localhost:3300/gis/public_service_point"
  //       );
  //       const public_point = response.data.map((x: any) => ({
  //         id: x.object_id,
  //         coordinates: x.geometry.split(",").map(Number),
  //         name: x.name,
  //         type: x.level2,
  //         property_id: x.property_id,
  //         level1: x.level1,
  //         level3: x.level3 || "null",
  //         level4: x.level4 || "null",
  //         level5: x.level5 || "null",
  //         level6: x.level6 || "null",
  //         level7: x.level7 || "null",
  //         remarks: x.remarks,
  //         district: x.district,
  //         mp_rmp: x.mp_rmp,
  //         area_ha: x.area_ha,
  //         shape_leng: x.shape_leng,
  //         shape_area: x.shape_area,
  //         ward_no: x.ward_no,
  //         cbscode: x.cbscode,
  //         orig_fid: x.orig_fid,
  //       }));
  //       setPublicPoint(public_point);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   getData();
  // }, []);

  const layers = useMemo(
    () => [
      new TileLayer({
        // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers
        data: "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        renderSubLayers: (props) => {
          const { boundingBox } = props.tile;
          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: boundingBox.flatMap((x) => x) as BitmapBoundingBox,
          });
        },
      }),

      new IconLayer({
        id: "public_point",
        data: reformattedData,
        pickable: true,
        iconAtlas: building,
        iconMapping: ICON_MAPPING,
        sizeScale: 5,
        getIcon: (d) => "public_point",
        getPosition: (d) => d.coordinates,
        getSize: (d) => 5,
        getColor: [245, 39, 58, 255],
        onClick: handleIconClick,
      }),
    ],
    [publicPoint]
  );

  return (
    <>
      <Sidebar items={items} />
      <DeckGL
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={layers}
      >
      </DeckGL>
      {renderPopup()}
      <InfoPanel
        info={data}
      />
    </>
  );
};

export default PublicServicePoint;
