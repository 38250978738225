import logo from "../assets/logo.png";
import SideBarItem from "./SideBarItem";
import { useContext, useEffect, useState } from 'react';
import { IoCloseOutline, IoArrowBackOutline, IoReorderThreeOutline, IoLogInOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import useAdmin from "../hooks/useAdmin";
import { IoLogOutOutline } from "react-icons/io5";
import { Button, Modal, Form, Offcanvas } from "react-bootstrap";
import axios from "axios";
import { token } from "../constants/token";
import { API_BASE_URL } from "../constants/api-constants";
import { InfoPanelContext, MapItemsContext, RefreshContext, WardContext } from "../App";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMobile from "../hooks/useMobile";

function Sidebar() {

  const success = () => toast.success('Success!')
  const failed = () => toast.error("Error!");

  const { mapItems, setMapItems } = useContext(MapItemsContext);

  const { infoPanel, setInfoPanel } = useContext(InfoPanelContext);

  const { refresh, setRefresh } = useContext(RefreshContext);

  const toggleRefresh = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  const mobile = useMobile();

  const { ward, setWard } = useContext(WardContext);

  const [show, setShow] = useState(mobile ? false : true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const admin = useAdmin();

  const navigate = useNavigate();

  const [logoutShow, setLogoutShow] = useState(false);

  const handleLogoutShow = () => setLogoutShow(true);
  const handleLogoutClose = () => setLogoutShow(false);

  const onLogout = () => {
    localStorage.removeItem("GISToken");
    localStorage.removeItem("GISUserRole");
    sessionStorage.removeItem("GISToken");
    sessionStorage.removeItem("GISUserRole");
    navigate(window.location.href = "/");
  }

  const [sidebarItems, setSidebarItems] = useState<any>();

  const config = {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  };

  const url = `${API_BASE_URL}/category`

  useEffect(() => {
    axios.get(url, config)
      .then(res => setSidebarItems(res.data))
      .catch(err => failed())
  }, [])

  const [cumulativeData, setCumulativeData] = useState();

  useEffect(() => {
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };

    const url =
      ward === "all_wards"
        ? `${API_BASE_URL}/gis/cumulative_data`
        : `${API_BASE_URL}/gis/cumulative_data/${ward}`

    axios.get(url, config)
      .then(res => setCumulativeData(res.data))
      .catch(err => failed())
  }, [ward])

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: mobile ? "row" : "column",
          justifyContent: mobile ? "space-between" : "normal",
          gap: "5px",
          alignItems: "center",
          padding: mobile ? "10px 12px" : "10px 0px",
          height: mobile ? "70px" : "100%",
          width: mobile ? "100%" : "50px",
          position: "absolute",
          zIndex: show ? "0" : "1",
          background: "white",
          borderRight: "1px solid lightgray",
        }}>
        <img src={logo} alt="Logo" width={mobile ? "60px" : "30px"} height={mobile ? "50px" : "30px"} />
        {mobile &&
          <h1 style={{ margin: "0px 0px 0px 10px", fontSize: "15px" }}>GIS - Chautara SangachokGadhi Municipality
            <br /><h1 style={{ fontSize: "13px", color: "#106eea", margin: "0px", marginTop: "3px" }}>Bagmati Province</h1>
          </h1>}
        <IoReorderThreeOutline cursor="pointer" onClick={handleShow} style={{ width: mobile ? "40px" : "30px", height: mobile ? "40px" : "30px", color: "gray" }} />
      </div>

      <Offcanvas show={show} onHide={handleClose} backdrop={false} scroll={true} placement={mobile ? "top" : "start"}
        style={{
          width: mobile ? "100%" : "275px",
          height: mobile ? "350px" : "100%"
        }}>
        {mobile ?
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
                alignItems: "center",
                padding: "10px 12px",
                height: "70px",
                width: "100%",
                position: "absolute",
                zIndex: "10",
                background: "white",
                borderRight: "1px solid lightgray",
              }}>
              <img src={logo} alt="Logo" width={mobile ? "60px" : "30px"} height={mobile ? "50px" : "30px"} />
              {mobile &&
                <h1 style={{ margin: "0px 0px 0px 10px", fontSize: "15px" }}>GIS - Chautara SangachokGadhi Municipality
                  <br /><h1 style={{ fontSize: "13px", color: "#106eea", margin: "0px", marginTop: "3px" }}>Bagmati Province</h1>
                </h1>}
              <IoReorderThreeOutline cursor="pointer" onClick={handleClose} style={{ width: "40px", height: "40px", color: "gray" }} />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "80px 15px 10px 12px" }}>
              {admin ?
                <>
                  <Link to="/dashboard" onClick={() => {
                    setMapItems([]);
                    setInfoPanel();
                    setWard("all_wards");
                  }} style={{ display: "flex", alignItems: "center", gap: "2px", textDecoration: "none", color: "#555956", fontSize: "14px" }}>
                    <IoArrowBackOutline style={{ width: "15px", height: "15px", marginTop: "-2px" }} />
                    <span>Back</span>
                  </Link>

                  <Form.Select
                    defaultValue={ward}
                    onChange={e => {
                      setWard(e.target.value);
                      toggleRefresh();
                    }
                    }
                    style={{
                      padding: "2px 30px 2px 15px",
                      fontSize: "13px",
                      color: "#555956",
                      fontWeight: "500",
                      border: "1px solid lightgray",
                      width: "auto"
                    }}>
                    <option value="all_wards">All Wards</option>
                    <option value={1}>Ward 1</option>
                    <option value={2}>Ward 2</option>
                    <option value={3}>Ward 3</option>
                    <option value={4}>Ward 4</option>
                    <option value={5}>Ward 5</option>
                    <option value={6}>Ward 6</option>
                    <option value={7}>Ward 7</option>
                    <option value={8}>Ward 8</option>
                    <option value={9}>Ward 9</option>
                    <option value={10}>Ward 10</option>
                    <option value={11}>Ward 11</option>
                    <option value={12}>Ward 12</option>
                    <option value={13}>Ward 13</option>
                    <option value={14}>Ward 14</option>
                  </Form.Select>

                  <div onClick={handleLogoutShow} style={{ display: "flex", alignItems: "center", gap: "2px", cursor: "pointer", color: "#555956", fontSize: "14px" }}>
                    <IoLogOutOutline style={{
                      width: "16px",
                      height: "17px",
                      marginTop: "-2px"
                    }} />
                    <span>Logout</span>
                  </div>
                </>
                :
                <>
                  <Form.Select
                    defaultValue={ward}
                    onChange={e => {
                      setWard(e.target.value);
                      toggleRefresh();
                    }
                    }
                    style={{
                      padding: "2px 30px 2px 15px",
                      fontSize: "13px",
                      color: "#555956",
                      fontWeight: "500",
                      border: "1px solid lightgray",
                      width: "auto"
                    }}>
                    <option value="all_wards">All Wards</option>
                    <option value={1}>Ward 1</option>
                    <option value={2}>Ward 2</option>
                    <option value={3}>Ward 3</option>
                    <option value={4}>Ward 4</option>
                    <option value={5}>Ward 5</option>
                    <option value={6}>Ward 6</option>
                    <option value={7}>Ward 7</option>
                    <option value={8}>Ward 8</option>
                    <option value={9}>Ward 9</option>
                    <option value={10}>Ward 10</option>
                    <option value={11}>Ward 11</option>
                    <option value={12}>Ward 12</option>
                    <option value={13}>Ward 13</option>
                    <option value={14}>Ward 14</option>
                  </Form.Select>

                  <Link to="/admin" onClick={() => {
                    setMapItems([]);
                    setInfoPanel();
                  }} style={{ textDecoration: "none" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "1px", cursor: "pointer", color: "#555956", fontSize: "14px" }}>
                      <IoLogInOutline style={{
                        width: "17px",
                        height: "17px",
                        marginTop: "-1px"
                      }} />
                      <span style={{ marginTop: "1px" }}>Login</span>
                    </div>
                  </Link>
                </>
              }
            </div>
          </>
          :
          <div className="sidebar-header" style={{ width: mobile ? "100%" : "275px" }}>
            <div style={{ position: "sticky", top: "0", backgroundColor: "white", paddingTop: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", margin: "0px 5px" }}>
                  <img src={logo} width="75px" height="65px" alt="Logo" />
                  <h1 style={{ margin: "0px", fontSize: "15px" }}>GIS - Chautara SangachokGadhi Municipality
                    <br /><h1 style={{ fontSize: "13px", color: "#106eea", margin: "0px", marginTop: "3px" }}>Bagmati Province</h1>
                  </h1>
                </div>
                <IoCloseOutline style={{ width: "35px", height: "35px", color: "#555956", marginTop: "-10px" }} cursor="pointer" onClick={handleClose} />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 5px" }}>
              {admin ?
                <>
                  <Link to="/dashboard" onClick={() => {
                    setMapItems([]);
                    setInfoPanel();
                    setWard("all_wards");
                  }} style={{ display: "flex", alignItems: "center", gap: "2px", textDecoration: "none", color: "#555956", fontSize: "14px" }}>
                    <IoArrowBackOutline style={{ width: "15px", height: "15px" }} />
                    <span>Back</span>
                  </Link>

                  <Form.Select
                    defaultValue={ward}
                    onChange={e => {
                      setWard(e.target.value);
                      toggleRefresh();
                    }
                    }
                    style={{
                      padding: "2px 30px 2px 15px",
                      fontSize: "13px",
                      color: "#555956",
                      fontWeight: "500",
                      border: "1px solid lightgray",
                      width: "auto",
                      cursor: "pointer"
                    }}>
                    <option value="all_wards">All Wards</option>
                    <option value={1}>Ward 1</option>
                    <option value={2}>Ward 2</option>
                    <option value={3}>Ward 3</option>
                    <option value={4}>Ward 4</option>
                    <option value={5}>Ward 5</option>
                    <option value={6}>Ward 6</option>
                    <option value={7}>Ward 7</option>
                    <option value={8}>Ward 8</option>
                    <option value={9}>Ward 9</option>
                    <option value={10}>Ward 10</option>
                    <option value={11}>Ward 11</option>
                    <option value={12}>Ward 12</option>
                    <option value={13}>Ward 13</option>
                    <option value={14}>Ward 14</option>
                  </Form.Select>

                  <div onClick={handleLogoutShow} style={{ display: "flex", alignItems: "center", gap: "2px", cursor: "pointer", color: "#555956", fontSize: "14px" }}>
                    <IoLogOutOutline style={{
                      width: "16px",
                      height: "17px",
                      marginTop: "-1px"
                    }} />
                    <span>Logout</span>
                  </div>
                </>
                :
                <>
                  <Form.Select
                    defaultValue={ward}
                    onChange={e => {
                      setWard(e.target.value);
                      toggleRefresh();
                    }
                    }
                    style={{
                      padding: "2px 30px 2px 15px",
                      fontSize: "13px",
                      color: "#555956",
                      fontWeight: "500",
                      border: "1px solid lightgray",
                      width: "auto",
                      cursor: "pointer"
                    }}>
                    <option value="all_wards">All Wards</option>
                    <option value={1}>Ward 1</option>
                    <option value={2}>Ward 2</option>
                    <option value={3}>Ward 3</option>
                    <option value={4}>Ward 4</option>
                    <option value={5}>Ward 5</option>
                    <option value={6}>Ward 6</option>
                    <option value={7}>Ward 7</option>
                    <option value={8}>Ward 8</option>
                    <option value={9}>Ward 9</option>
                    <option value={10}>Ward 10</option>
                    <option value={11}>Ward 11</option>
                    <option value={12}>Ward 12</option>
                    <option value={13}>Ward 13</option>
                    <option value={14}>Ward 14</option>
                  </Form.Select>

                  <Link to="/admin"
                    onClick={() => {
                      setMapItems([]);
                      setInfoPanel();
                    }}
                    style={{ textDecoration: "none" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "1px", cursor: "pointer", color: "#555956", fontSize: "14px" }}>
                      <IoLogInOutline style={{
                        width: "17px",
                        height: "17px",
                        marginTop: "-1px"
                      }} />
                      <span style={{ marginTop: "1px" }}>Login</span>
                    </div>
                  </Link>
                </>
              }
            </div>
            <hr style={{ margin: "0px -5px 0px -10px" }} />
          </div>
        }
        <div className="sidebar-body"
          style={{
            width: mobile ? "100%" : "275px",
            padding: mobile ? "0px" : "145px 0px 0px 0px",
          }}>
          {sidebarItems !== undefined ? sidebarItems.map((item: any, index: number) => (
            <SideBarItem key={index} item={item} smallFont={false} cumulativeData={cumulativeData} />
          ))
            :
            ""}
        </div>

        <hr style={{ margin: "0px", border: "1px solid black" }} />

        {/* <div className="sidebar-footer"
          style={{
            width: mobile ? "100%" : "260px",
            padding: mobile ? "10px 15px" : "10px 10px"
          }}>
          {admin ?
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Link to="/dashboard" onClick={() => {
                setMapItems([]);
                setInfoPanel();
              }} style={{ display: "flex", alignItems: "center", gap: "2px", textDecoration: "none", color: "#555956", fontSize: "14px" }}>
                <IoArrowBackOutline style={{ width: "15px", height: "15px", marginTop: "-1px" }} />
                <span>Back</span>
              </Link>

              <div onClick={handleLogoutShow} style={{ display: "flex", alignItems: "center", gap: "2px", cursor: "pointer", color: "#555956", fontSize: "14px" }}>
                <IoLogOutOutline style={{
                  width: "16px",
                  height: "17px",
                  marginTop: "-1px"
                }} />
                <span style={{ marginTop: "1px" }}>Logout</span>
              </div>
            </div>
            :
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Link to="/admin" onClick={() => {
                setMapItems([]);
                setInfoPanel();
              }} style={{ marginLeft: "auto", marginRight: "auto", textDecoration: "none" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "2px", cursor: "pointer", color: "#555956", fontSize: "14px" }}>
                  <IoLogInOutline style={{
                    width: "18px",
                    height: "18px",
                    marginTop: "-1px"
                  }} />
                  <span style={{ marginTop: "1px" }}>Login</span>
                </div>
              </Link>
            </div>
          }
        </div> */}
      </Offcanvas >

      <Modal show={logoutShow} onHide={handleLogoutClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to logout?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLogoutClose}>
            No
          </Button>
          <Button variant="primary" onClick={() => onLogout()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={1}
      />
    </>
  );
}

export default Sidebar;
