import DeckGL from "@deck.gl/react/typed";
import { useMemo } from "react";
import { TileLayer } from "@deck.gl/geo-layers/typed";
import {
  BitmapBoundingBox,
  BitmapLayer,
  PolygonLayer,
} from "@deck.gl/layers/typed";
import { publicService } from "../data/PublicService";
import Sidebar from "./SideBar";
import items from "../assets/data.json";

const INITIAL_VIEW_STATE = {
  longitude: 85.71490102721086,
  latitude: 27.75991528556676,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};


const PublicService = () => {
  const layers = useMemo(
    () => [
      new TileLayer({
        // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers
        data: "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        renderSubLayers: (props) => {
          const { boundingBox } = props.tile;
          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: boundingBox.flatMap((x) => x) as BitmapBoundingBox,
          });
        },
      }),

      new PolygonLayer({
        id: "polygon-layer",
        data: publicService,
        pickable: true,
        stroked: true,
        filled: true,
        wireframe: true,
        lineWidthMinPixels: 1,
        getPolygon: (d) => d.coordinates[0],
        getLineColor: [245, 39, 58, 255],
        getFillColor: [245, 39, 58, 255],
        getLineWidth: 1,
      }),
    ],
    []
  );
  return (
    <>
      {/* <Sidebar items={items} /> */}
      <Sidebar />
      <DeckGL
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={layers}
        getTooltip={({ object }) => object && `${object.name}`}
      ></DeckGL>
    </>
  );
};

export default PublicService;
