import "./App.css";
import Login from "./components/Login";
import Map2 from "./components/Map2";
import CulturalPoint from "./components/Cultural";
import PublicService from "./components/Public";
import PublicServicePoint from "./components/PublicPoint";
import LandCover from "./components/Landcover";
import Road from "./components/Road";
import Error from "./components/ErrorPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { createContext, useState, useEffect } from "react";
import useAdmin from "./hooks/useAdmin";
import { API_BASE_URL } from "./constants/api-constants";
import axios from "axios";
import { token } from "./constants/token";

export const RefreshContext = createContext<any>({})

export const MapItemsContext = createContext<any>([]);

export const InfoPanelContext = createContext<any>([]);

export const WardContext = createContext<any>([]);

export const LoadingContext = createContext<any>([]);

const App = () => {

  const [refresh, setRefresh] = useState(false);

  const [mapItems, setMapItems] = useState<Array<string>>([]);

  const [infoPanel, setInfoPanel] = useState();

  const [ward, setWard] = useState("all_wards");

  const [loading, setLoading] = useState(false);

  const [login, setLogin] = useState(false);

  const admin = useAdmin();

  useEffect(() => {
    // const session_access_token = sessionStorage.getItem("GISToken");
    // const local_access_token = localStorage.getItem("GISToken");
    // if (local_access_token === null && session_access_token === null) {
    //   setLogin(false);
    // }

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };

    const url = `${API_BASE_URL}/auth/me`

    axios.get(url, config)
      .then(res => {
        res.data.error ? setLogin(false) : setLogin(true)
      })
      .catch(err => setLogin(false))

  }, [])

  const routes = [
    {
      path: "/",
      component: admin ? Dashboard : Map2,
    },
    {
      path: "/admin",
      component: login && admin ? Dashboard : Login,
    },
    {
      path: "/dashboard",
      component: admin ? Dashboard : Login,
    },
    {
      path: "/map",
      component: Map2,
    },
    {
      path: "/cultural",
      component: login ? CulturalPoint : Login,
    },
    {
      path: "/public",
      component: login ? PublicService : Login,
    },
    {
      path: "/public-point",
      component: login ? PublicServicePoint : Login,
    },
    {
      path: "/land-cover",
      component: login ? LandCover : Login,
    },
    {
      path: "/road",
      component: login ? Road : Login,
    },
    {
      path: "*",
      component: Error
    },
  ];

  return (
    <RefreshContext.Provider value={{ refresh, setRefresh }}>
      <MapItemsContext.Provider value={{ mapItems, setMapItems }}>
        <InfoPanelContext.Provider value={{ infoPanel, setInfoPanel }}>
          <WardContext.Provider value={{ ward, setWard }}>
            <LoadingContext.Provider value={{ loading, setLoading }}>
              <Router>
                <div className="map-container">
                  <Routes>
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        Component={route.component}
                      />
                    ))}
                  </Routes>
                </div>
              </Router>
            </LoadingContext.Provider>
          </WardContext.Provider>
        </InfoPanelContext.Provider>
      </MapItemsContext.Provider>
    </RefreshContext.Provider>
  );
};

export default App;
