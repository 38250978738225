//@ts-nocheck
import DeckGL from "@deck.gl/react/typed";
import { FlyToInterpolator } from "deck.gl";
import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { TileLayer } from "@deck.gl/geo-layers/typed";
import {
    BitmapBoundingBox,
    BitmapLayer,
    IconLayer,
    IconLayerProps,
    PolygonLayer,
    GeoJsonLayer
} from "@deck.gl/layers/typed";
import cultural from "../assets/icons/cultural.png"
import axios from "axios";
import InfoPanel from "./InfoPanel";
import Sidebar from "./SideBar";
import { API_BASE_URL } from "../constants/api-constants";
import { token } from "../constants/token";
import { InfoPanelContext, LoadingContext, MapItemsContext, RefreshContext, WardContext } from "../App";
import building from "../assets/icons/home.png";
import health from "../assets/icons/health.png";
import education from "../assets/icons/education.png";
import numbers from "../assets/icons/numbers.png";
import { WebMercatorViewport } from 'viewport-mercator-project';
import { PathStyleExtension } from '@deck.gl/extensions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMobile from "../hooks/useMobile";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// const INITIAL_VIEW_STATE = {
//     longitude: 85.71490102721086,
//     latitude: 27.75991528556676,
//     zoom: 10.6,
//     pitch: 0,
//     bearing: 0,
// };

const ICON_MAPPING: IconLayerProps["iconMapping"] = {
    temple: { x: 8, y: 8, width: 111, height: 111, mask: false },
    stupa: { x: 128, y: 0, width: 127, height: 127, mask: false },
    church: { x: 256, y: 0, width: 103, height: 127, mask: false },
    public_point: { x: 0, y: 0, width: 512, height: 512, mask: true },
    health_service: { x: 0, y: 0, width: 2048, height: 2048, mask: false },
    education: { x: 0, y: 0, width: 1200, height: 1685, mask: false },
    ward1: { x: 34, y: 24, width: 44, height: 44, mask: false },
    ward2: { x: 96, y: 24, width: 44, height: 44, mask: false },
    ward3: { x: 158, y: 24, width: 44, height: 44, mask: false },
    ward4: { x: 220, y: 24, width: 44, height: 44, mask: false },
    ward5: { x: 282, y: 24, width: 44, height: 44, mask: false },
    ward6: { x: 34, y: 92, width: 44, height: 44, mask: false },
    ward7: { x: 96, y: 92, width: 44, height: 44, mask: false },
    ward8: { x: 158, y: 92, width: 44, height: 44, mask: false },
    ward9: { x: 220, y: 92, width: 44, height: 44, mask: false },
    ward10: { x: 282, y: 92, width: 44, height: 44, mask: false },
    ward11: { x: 34, y: 158, width: 44, height: 44, mask: false },
    ward12: { x: 96, y: 158, width: 44, height: 44, mask: false },
    ward13: { x: 158, y: 158, width: 44, height: 44, mask: false },
    ward14: { x: 220, y: 158, width: 44, height: 44, mask: false },
};

interface CustomPopupProps {
    longitude: number;
    latitude: number;
    children: React.ReactNode;
    onClose: () => void;
}

export const CustomPopup: React.FC<CustomPopupProps> = ({
    position,
    children,
    onClose,
}) => {

    const data = children[0];
    const [name, setName] = useState(data.name);
    const [coordinates, setCoordinates] = useState(data.coordinates);
    const coordinatesString = Array.isArray(coordinates)
        ? `${coordinates.join(", ")}`
        : coordinates;

    useEffect(() => {
        setName(data.name);
        setCoordinates(data.coordinates);
    }, [children]);

    const popupStyle: React.CSSProperties = {
        position: "absolute",
        left: 0,
        top: 0,
        transform: `translate(${children[3] !== undefined && children[3].x - 170}px, ${children[3] !== undefined && children[3].y - 75}px)`,
        backgroundColor: "white",
        padding: "5px",
        border: "1px solid darkgray",
        borderRadius: "5px",
        zIndex: 100,
        fontSize: "12px",
    };

    const arrow: React.CSSProperties = {
        position: "absolute",
        width: "12px",
        height: "12px",
        backgroundColor: "white",
        transform: "rotate(45deg)",
        bottom: "-7px",
        left: "48%",
        borderBottom: "1px solid gray",
        borderRight: "1px solid gray",
    };

    const buttonStyle: React.CSSProperties = {
        marginLeft: "5px",
        backgroundColor: "white",
        padding: "5px",
        border: "1px ",
        borderRadius: "10px",
        zIndex: 9999,
    };

    return (
        <div style={popupStyle}>
            <div style={arrow}></div>
            {
                Object.keys(data).map((field) => (
                    <div key={field}>
                        {field.toUpperCase()}:{" "}
                        {data[field] !== null ? data[field] : "-"}
                        <br />
                    </div>
                ))
            }
        </div >
    );
};

const Map2 = () => {
    const mobile = useMobile();

    const [initialViewState, setInitialViewState] = useState({
        longitude: 85.72490102721086,
        latitude: mobile ? 27.76591528556676 : 27.75191528556676,
        zoom: mobile ? 10.6 : 10.78,
        pitch: 0,
        bearing: 0,
    })

    const { refresh, setRefresh } = useContext(RefreshContext);

    const { loading, setLoading } = useContext(LoadingContext);
    const [municipalityBoundaryLoading, setMunicipalityBoundaryLoading] = useState(false);
    const [wardBoundaryLoading, setWardBoundaryLoading] = useState(false);
    const [wardOfficeLoading, setWardOfficeLoading] = useState(false);
    const [localRoadLoading, setLocalRoadLoading] = useState(false);
    const [highwayLoading, setHighwayLoading] = useState(false);
    const [culturalLoading, setCulturalLoading] = useState(false);
    const [publicLoading, setPublicLoading] = useState(false);
    const [educationLoading, setEducationLoading] = useState(false);
    const [healthLoading, setHealthLoading] = useState(false);
    const [openSpaceLoading, setOpenSpaceLoading] = useState(false);
    const [waterBodiesLoading, setWaterBodiesLoading] = useState(false);
    const [geologyLoading, setGeologyLoading] = useState(false);
    const [landFormLoading, setLandFormLoading] = useState(false);
    const [builtUpLoading, setBuiltUpLoading] = useState(false);
    const [economicLoading, setEconomicLoading] = useState(false);
    const [agricultureLoading, setAgricultureLoading] = useState(false);
    const [forestLoading, setForestLoading] = useState(false);
    // const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     axios.interceptors.request.use(
    //         (config) => {
    //             setLoading(true);
    //             return config;
    //         },
    //         (error) => {
    //             return Promise.reject(error);
    //         }
    //     );

    //     axios.interceptors.response.use(
    //         (config) => {
    //             setLoading(false);
    //             return config;
    //         },
    //         (error) => {
    //             return Promise.reject(error);
    //         }
    //     );
    // }, [])

    const success = () => toast.success('Success!')
    const failed = () => toast.error("Error! Data was not fetched properly.");

    const { mapItems, setMapItems } = useContext(MapItemsContext);

    const { infoPanel, setInfoPanel } = useContext(InfoPanelContext);

    const { ward, setWard } = useContext(WardContext);

    const [selectedFeature, setSelectedFeature] = useState(null);
    const [culturalPoint, setCulturalPoint] = useState([]);
    const [popupData, setPopupData] = useState(null);
    const [InfoPanelData, setInfoPanelData] = useState(null);

    const reformattedCulturalData = [];

    // Iterate through the original data
    culturalPoint !== null && culturalPoint.forEach(item => {
        // Create a copy of the item
        const newItem = { ...item };

        // Remove the 'attributes' property from the new item
        delete newItem.attributes;

        // Merge the 'attributes' properties into the new item
        Object.assign(newItem, item.attributes);
        // Object.assign(newItem, JSON.parse(item.attributes));

        // Push the reformatted item to the new array
        reformattedCulturalData.push(newItem);
    });

    const reformattedWardBoundary = []
    const [wardBoundary, setWardBoundary] = useState([]);

    wardBoundary !== null && wardBoundary.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedWardBoundary.push(newItem);
    });

    const reformattedWardOffice = []
    const [wardOffice, setWardOffice] = useState([]);

    wardOffice !== null && wardOffice.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedWardOffice.push(newItem);
    });

    const reformattedMunicipalityBoundary = []
    const [municipalityBoundary, setMunicipalityBoundary] = useState([]);

    municipalityBoundary !== null && municipalityBoundary.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedMunicipalityBoundary.push(newItem);
    });

    const reformattedPublicData = []
    const [publicPoint, setPublicPoint] = useState([]);

    publicPoint !== null && publicPoint.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedPublicData.push(newItem);
    });

    const reformattedEducationData = []
    const [educationPoint, setEducationPoint] = useState([]);

    educationPoint !== null && educationPoint.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedEducationData.push(newItem);
    });

    const reformattedHealthData = []
    const [healthPoint, setHealthPoint] = useState([]);

    healthPoint !== null && healthPoint.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedHealthData.push(newItem);
    });

    const reformattedRoadData = []
    const [roadNetworkConnectivity, setRoadNetworkConnectivity] = useState([]);

    roadNetworkConnectivity !== null && roadNetworkConnectivity.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedRoadData.push(newItem);
    });

    const reformattedHighwayData = []
    const [highwayData, setHighwayData] = useState([]);

    highwayData !== null && highwayData.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedHighwayData.push(newItem);
    });

    const reformattedOpenSpaceData = []
    const [openSpace, setOpenSpace] = useState([]);

    openSpace !== null && openSpace.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedOpenSpaceData.push(newItem);
    });

    const reformattedWaterBodiesData = []
    const [waterBodies, setWaterBodies] = useState([]);

    waterBodies !== null && waterBodies.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedWaterBodiesData.push(newItem);
    });

    const reformattedGeologyData = []
    const [geology, setGeology] = useState([]);

    geology !== null && geology.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedGeologyData.push(newItem);
    });

    const reformattedLandFormData = []
    const [landForm, setLandForm] = useState([]);

    landForm !== null && landForm.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedLandFormData.push(newItem);
    });

    const reformattedBuiltUpData = []
    const [builtUp, setBuiltUp] = useState([]);

    builtUp !== null && builtUp.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedBuiltUpData.push(newItem);
    });

    const reformattedAgriculture = []
    const [agriculture, setAgriculture] = useState([]);

    agriculture !== null && agriculture.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedAgriculture.push(newItem);
    });

    const reformattedEconomicConnectivity = []
    const [economicConnectivity, setEconomicConnectivity] = useState([]);

    economicConnectivity !== null && economicConnectivity.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedEconomicConnectivity.push(newItem);
    });

    const reformattedUrbanGreenery = []
    const [urbanGreenery, setUrbanGreenery] = useState([]);

    urbanGreenery !== null && urbanGreenery.forEach(item => {
        const newItem = { ...item };
        delete newItem.attributes;
        Object.assign(newItem, item.attributes);
        reformattedUrbanGreenery.push(newItem);
    });

    useEffect(() => {
        if (municipalityBoundaryLoading ||
            wardBoundaryLoading ||
            wardOfficeLoading ||
            localRoadLoading ||
            highwayLoading ||
            culturalLoading ||
            publicLoading ||
            educationLoading ||
            healthLoading ||
            openSpaceLoading ||
            waterBodiesLoading ||
            geologyLoading ||
            landFormLoading ||
            builtUpLoading ||
            economicLoading ||
            agricultureLoading ||
            forestLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [municipalityBoundaryLoading,
        wardBoundaryLoading,
        wardOfficeLoading,
        localRoadLoading,
        highwayLoading,
        culturalLoading,
        publicLoading,
        educationLoading,
        healthLoading,
        openSpaceLoading,
        waterBodiesLoading,
        geologyLoading,
        landFormLoading,
        builtUpLoading,
        economicLoading,
        agricultureLoading,
        forestLoading,
        refresh])

    const [popupInfo, setPopupInfo] = useState();

    const handleIconClick = (object: any) => {
        setInfoPanel();
        if (object.layer.id === "highway" ||
            object.layer.id === "local-road" ||
            object.layer.id === "urban-greenery" ||
            object.layer.id === "water-bodies" ||
            object.layer.id === "economic-connectivity" ||
            object.layer.id === "geology" ||
            object.layer.id === "land-form" ||
            object.layer.id === "built-up" ||
            object.layer.id === "building-typology" ||
            object.layer.id === "open-space" ||
            object.layer.id === "agriculture") {
            setSelectedFeature(object);
            // } else if (object.layer.id === "land-form") {
            //     setSelectedFeature(object.properties);
        } else {
            const newObject = {
                object: {
                    name: object.object.name,
                    coordinates: `[${object.object.coordinates[0]}, ${object.object.coordinates[1]}]`
                },
                x: object.x,
                y: object.y
            }
            setSelectedFeature(object);
            setPopupData(newObject);
            setPopupInfo(object);
        }
    };

    useEffect(() => {
        setInfoPanelData(selectedFeature);
    }, [refresh, selectedFeature])

    const [updatedPopup, setUpdatedPopup] = useState();

    const renderPopup = () => {
        if (popupData) {
            const { x, y, object } = popupData;
            return (
                <div id="custom-popup">
                    <CustomPopup
                        position={object.position}
                        onClose={() => setPopupData(null)}
                    >
                        {object}
                        {x}
                        {y}
                        {updatedPopup}
                    </CustomPopup>
                </div>
            );
        }
        return null;
    };

    useEffect(() => {
        setWardBoundaryLoading(true);
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        const url = `${API_BASE_URL}/gis/ward_boundary`

        axios.get(url, config)
            .then(res => {
                res.data.map(item => {
                    setWardBoundary(res.data);
                })
                setWardBoundaryLoading(false);
            })
            .catch(err => {
                failed()
                setWardBoundaryLoading(false)
            })
    }, [refresh]);

    useEffect(() => {
        setWardOfficeLoading(true);
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        const url = `${API_BASE_URL}/gis/ward_office`

        axios.get(url, config)
            .then(res => {
                res.data.map(item => {
                    setWardOffice(res.data);
                })
                setWardOfficeLoading(false);
            })
            .catch(err => {
                failed()
                setWardOfficeLoading(false)
            })
    }, [refresh]);

    useEffect(() => {
        setMunicipalityBoundaryLoading(true)
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        const url = `${API_BASE_URL}/gis/municipality_boundary`

        axios.get(url, config)
            .then(res => {
                res.data.map(item => {
                    setMunicipalityBoundary(res.data);
                })
                setMunicipalityBoundaryLoading(false)
            })
            .catch(err => {
                setMunicipalityBoundaryLoading(false)
                failed()
            })
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Cultural and Religious Sites") ||
            mapItems.includes("Existing Archaeological Sites/Monuments") ||
            mapItems.includes("Archaeological Excavation Sites") ||
            mapItems.includes("Culture Aspects (Tangible/Intangible)")) {
            setCulturalLoading(true);

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/cultural_point`

            const newData = [];

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        const splitCoordinates = item.geometry.split(",");
                        const coordinates = [parseFloat(splitCoordinates[0]), parseFloat(splitCoordinates[1])]
                        item.coordinates = coordinates;
                        newData.push(item);
                    })
                    setCulturalPoint(newData)
                    setCulturalLoading(false)
                })
                .catch(err => {
                    setCulturalLoading(false)
                    failed()
                })
        } else {
            setCulturalPoint(null);
            setInfoPanelData(null);
        }
    }, [refresh])

    useEffect(() => {
        if (mapItems.includes("Utility Services")) {
            setPublicLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/public_service_point`

            const newData = [];

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        const splitCoordinates = item.geometry.split(",");
                        const coordinates = [parseFloat(splitCoordinates[0]), parseFloat(splitCoordinates[1])]
                        item.coordinates = coordinates;
                        newData.push(item);
                    })
                    setPublicPoint(newData);
                    setPublicLoading(false)
                })
                .catch(err => {
                    setPublicLoading(false)
                    failed()
                })
        } else {
            setPublicPoint(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Education")) {
            setEducationLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/education_point`

            const newData = [];

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        const splitCoordinates = item.geometry.split(",");
                        const coordinates = [parseFloat(splitCoordinates[0]), parseFloat(splitCoordinates[1])]
                        item.coordinates = coordinates;
                        newData.push(item);
                    })
                    setEducationPoint(newData);
                    setEducationLoading(false)
                })
                .catch(err => {
                    setEducationLoading(false)
                    failed()
                })
        } else {
            setEducationPoint(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Health Services")) {
            setHealthLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/health_service_point`

            const newData = [];

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        const splitCoordinates = item.geometry.split(",");
                        const coordinates = [parseFloat(splitCoordinates[0]), parseFloat(splitCoordinates[1])]
                        item.coordinates = coordinates;
                        newData.push(item);
                    })
                    setHealthPoint(newData);
                    setHealthLoading(false)
                })
                .catch(err => {
                    setHealthLoading(false)
                    failed()
                })
        } else {
            setHealthPoint(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Road Network Connectivity") ||
            mapItems.includes("Internal Road Connectivity")) {
            setLocalRoadLoading(true);

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/road_network_local_road`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setRoadNetworkConnectivity(res.data);
                    })
                    setLocalRoadLoading(false);
                })
                .catch(err => {
                    failed();
                    setLocalRoadLoading(false);
                })
        } else {
            setRoadNetworkConnectivity(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Road Network Connectivity") ||
            mapItems.includes("Internal Road Connectivity")) {
            setHighwayLoading(true);

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/road_network_highway`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setHighwayData(res.data);
                    })
                    setHighwayLoading(false)
                })
                .catch(err => {
                    failed();
                    setHighwayLoading(false);
                })
        } else {
            setHighwayData(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Open Space")) {
            setOpenSpaceLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/open_space`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setOpenSpace(res.data);
                    })
                    setOpenSpaceLoading(false)
                })
                .catch(err => {
                    setOpenSpaceLoading(false)
                    failed()
                })
        } else {
            setOpenSpace(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Lakes/Ponds (Water Bodies)") ||
            mapItems.includes("Land Use") ||
            mapItems.includes("River System")) {
            setWaterBodiesLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/water_bodies`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setWaterBodies(res.data);
                    })
                    setWaterBodiesLoading(false)
                })
                .catch(err => {
                    setWaterBodiesLoading(false)
                    failed()
                })
        } else {
            setWaterBodies(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Geology")) {
            setGeologyLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/geology`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setGeology(res.data);
                    })
                    setGeologyLoading(false)
                })
                .catch(err => {
                    setGeologyLoading(false)
                    failed()
                })
        } else {
            setGeology(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Land Form and Soil")) {
            setLandFormLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/land_form_soil`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setLandForm(res.data);
                    })
                    setLandFormLoading(false)
                })
                .catch(err => {
                    setLandFormLoading(false)
                    failed()
                })
        } else {
            setLandForm(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Build-Up Pattern") ||
            mapItems.includes("Building Typology") ||
            mapItems.includes("Land Use")) {
            setBuiltUpLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/built_up_area`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setBuiltUp(res.data);
                    })
                    setBuiltUpLoading(false)
                })
                .catch(err => {
                    setBuiltUpLoading(false)
                    failed()
                })
        } else {
            setBuiltUp(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Land Use")) {
            setAgricultureLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/agriculture`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setAgriculture(res.data);
                    })
                    setAgricultureLoading(false)
                })
                .catch(err => {
                    setAgricultureLoading(false)
                    failed()
                })
        } else {
            setAgriculture(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Economic Connectivity")) {
            setEconomicLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/economic_connectivity`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setEconomicConnectivity(res.data);
                    })
                    setEconomicLoading(false)
                })
                .catch(err => {
                    setEconomicLoading(false)
                    failed()
                })
        } else {
            setEconomicConnectivity(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    useEffect(() => {
        if (mapItems.includes("Urban Greenery") ||
            mapItems.includes("Land Use")) {
            setForestLoading(true)

            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            const url = `${API_BASE_URL}/gis/forest`

            axios.get(url, config)
                .then(res => {
                    res.data.map(item => {
                        setUrbanGreenery(res.data);
                    })
                    setForestLoading(false)
                })
                .catch(err => {
                    setForestLoading(false)
                    failed()
                })
        } else {
            setUrbanGreenery(null);
            setInfoPanelData(null);
        }
    }, [refresh]);

    const layers = useMemo(
        () => [
            new TileLayer({
                // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers
                data: "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
                minZoom: 0,
                maxZoom: 19,
                tileSize: 256,
                renderSubLayers: (props) => {
                    const { boundingBox } = props.tile;
                    return new BitmapLayer(props, {
                        data: undefined,
                        image: props.data,
                        bounds: boundingBox.flatMap((x) => x) as BitmapBoundingBox,
                    });
                },
            }),

            new GeoJsonLayer({
                id: "open-space",
                data: ward === "all_wards" ? reformattedOpenSpaceData : reformattedOpenSpaceData.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 1,
                getPolygon: (d) => d.geometry[0],
                getLineColor: [255, 174, 55],
                getFillColor: [255, 174, 55],
                getLineWidth: 1,
                onClick: handleIconClick
            }),

            new GeoJsonLayer({
                id: "geology",
                data: ward === "all_wards" ? reformattedGeologyData : reformattedGeologyData.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 1,
                getPolygon: (d) => d.geometry[0],
                getLineColor: [8, 14, 44, 100],
                getFillColor: [16, 14, 285, 50],
                getLineWidth: 1,
                onClick: handleIconClick
            }),

            new GeoJsonLayer({
                id: "land-form",
                data: ward === "all_wards" ? reformattedLandFormData : reformattedLandFormData.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 1,
                getPolygon: (d) => d.geometry[0],
                getLineColor: [8, 14, 44, 100],
                getFillColor: [16, 14, 285, 50],
                getLineWidth: 1,
                onClick: handleIconClick
            }),

            new PolygonLayer({
                id: "agriculture",
                data: ward === "all_wards" ? reformattedAgriculture : reformattedAgriculture.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 1,
                getPolygon: (d) => d.geometry.coordinates[0],
                getLineColor: [255, 254, 209],
                getFillColor: [255, 254, 209],
                getLineWidth: 1,
                onClick: handleIconClick
            }),

            new GeoJsonLayer({
                id: "built-up",
                data: ward === "all_wards" ? reformattedBuiltUpData : reformattedBuiltUpData.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 1,
                getPolygon: (d) => d.geometry[0],
                getLineColor: [255, 154, 155],
                getFillColor: [255, 154, 155],
                getLineWidth: 1,
                onClick: handleIconClick
            }),

            new GeoJsonLayer({
                id: "building-typology",
                data: ward === "all_wards" ? reformattedBuiltUpData : reformattedBuiltUpData.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 1,
                getPolygon: (d) => d.geometry[0],
                getLineColor: [255, 154, 155],
                getFillColor: [255, 154, 155],
                getLineWidth: 1,
                onClick: handleIconClick
            }),

            new PolygonLayer({
                id: "urban-greenery",
                data: ward === "all_wards" ? reformattedUrbanGreenery : reformattedUrbanGreenery.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 1,
                getPolygon: (d) => d.geometry.coordinates[0],
                getLineColor: [165, 253, 153],
                getFillColor: [165, 253, 153],
                getLineWidth: 1,
                onClick: handleIconClick
            }),

            new GeoJsonLayer({
                id: "water-bodies",
                data: ward === "all_wards" ? reformattedWaterBodiesData : reformattedWaterBodiesData.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 1,
                getPolygon: (d) => d.geometry[0],
                getLineColor: [0, 146, 251, 100],
                getFillColor: [0, 146, 251],
                getLineWidth: 1,
                onClick: handleIconClick
            }),

            new PolygonLayer({
                id: "ward-boundary",
                data: reformattedWardBoundary,
                pickable: false,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 1,
                getPolygon: (d) => d.geometry.coordinates[0],
                getFillColor: [255, 154, 155, 0],
                getLineWidth: 1,
                getDashArray: [10, 10],
                dashJustified: true,
                dashGapPickable: true,
                extensions: [new PathStyleExtension({ dash: true })]
            }),

            new PolygonLayer({
                id: "municipality-boundary",
                data: reformattedMunicipalityBoundary,
                pickable: false,
                stroked: true,
                filled: true,
                wireframe: true,
                lineWidthMinPixels: 3,
                getPolygon: (d) => d.geometry.coordinates[0],
                getLineColor: [135, 135, 135],
                getFillColor: [255, 154, 155, 0],
                getLineWidth: 3,
                getDashArray: [10, 10],
                dashJustified: true,
                dashGapPickable: true,
                extensions: [new PathStyleExtension({ dash: true })],
            }),


            new GeoJsonLayer({
                id: "local-road",
                data: ward === "all_wards" ? reformattedRoadData : reformattedRoadData.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: false,
                filled: true,
                extruded: true,
                pointType: 'circle',
                lineWidthScale: 2,
                lineWidthMinPixels: 2,
                getLineColor: [255, 80, 44, 200],
                getPointRadius: 100,
                getLineWidth: 2,
                getElevation: 30,
                onClick: handleIconClick,
                autoHighlight: true,
                highlightColor: [16, 14, 285]
            }),

            new GeoJsonLayer({
                id: "highway",
                data: ward === "all_wards" ? reformattedHighwayData : reformattedHighwayData.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: false,
                filled: true,
                extruded: true,
                pointType: 'circle',
                lineWidthScale: 4,
                lineWidthMinPixels: 4,
                getLineColor: [255, 80, 44],
                getPointRadius: 100,
                getLineWidth: 4,
                getElevation: 30,
                onClick: handleIconClick,
                autoHighlight: true,
                highlightColor: [16, 14, 285]
            }),

            new GeoJsonLayer({
                id: "economic-connectivity",
                data: ward === "all_wards" ? reformattedEconomicConnectivity : reformattedEconomicConnectivity.filter(data => data.ward_no == ward),
                pickable: true,
                stroked: false,
                filled: true,
                extruded: true,
                pointType: 'circle',
                lineWidthScale: 2,
                lineWidthMinPixels: 2,
                getLineColor: [225, 58, 250],
                getPointRadius: 100,
                getLineWidth: 2,
                getElevation: 30,
                onClick: handleIconClick,
                autoHighlight: true,
                highlightColor: [16, 14, 285]
            }),

            new IconLayer({
                id: "ward_office",
                data: reformattedWardOffice,
                pickable: true,
                iconAtlas: numbers,
                iconMapping: ICON_MAPPING,
                sizeScale: 3,
                getIcon: (d: any) => {
                    switch (d.ward_no) {
                        case 1:
                            return "ward1";
                        case 2:
                            return "ward2";
                        case 3:
                            return "ward3";
                        case 4:
                            return "ward4";
                        case 5:
                            return "ward5";
                        case 6:
                            return "ward6";
                        case 7:
                            return "ward7";
                        case 8:
                            return "ward8";
                        case 9:
                            return "ward9";
                        case 10:
                            return "ward10";
                        case 11:
                            return "ward11";
                        case 12:
                            return "ward12";
                        case 13:
                            return "ward13";
                        case 14:
                            return "ward14";
                    }
                },
                getPosition: (d) => d.geometry,
                getSize: (d) => 6,
            }),

            new IconLayer({
                id: "cultural_point",
                data: ward === "all_wards" ? reformattedCulturalData : reformattedCulturalData.filter(data => data.ward_no == ward),
                pickable: true,
                iconAtlas: cultural,
                iconMapping: ICON_MAPPING,
                sizeScale: 3,
                getIcon: (d: any) => {
                    switch (d.level2) {
                        case "s":
                            return "stupa";
                        case "t":
                            return "temple";
                        case "o":
                            return "church";
                    }
                },
                getPosition: (d) => d.coordinates,
                getSize: (d) => 6,
                onClick: handleIconClick,
            }),

            new IconLayer({
                id: "public_point",
                data: ward === "all_wards" ? reformattedPublicData : reformattedPublicData.filter(data => data.ward_no == ward),
                pickable: true,
                iconAtlas: building,
                iconMapping: ICON_MAPPING,
                sizeScale: 4,
                getIcon: (d) => "public_point",
                getPosition: (d) => d.coordinates,
                getSize: (d) => 5,
                getColor: [245, 39, 58, 255],
                onClick: handleIconClick,
            }),

            new IconLayer({
                id: "education_point",
                data: ward === "all_wards" ? reformattedEducationData : reformattedEducationData.filter(data => data.ward_no == ward),
                pickable: true,
                iconAtlas: education,
                iconMapping: ICON_MAPPING,
                sizeScale: 5,
                getIcon: (d) => "education",
                getPosition: (d) => d.coordinates,
                getSize: (d) => 4,
                onClick: handleIconClick,
            }),

            new IconLayer({
                id: "health_point",
                data: ward === "all_wards" ? reformattedHealthData : reformattedHealthData.filter(data => data.ward_no == ward),
                pickable: true,
                iconAtlas: health,
                iconMapping: ICON_MAPPING,
                sizeScale: 5,
                getIcon: (d) => "health_service",
                getPosition: (d) => d.coordinates,
                getSize: (d) => 3,
                onClick: handleIconClick,
            }),
        ],
        [culturalPoint, publicPoint, educationPoint, healthPoint, roadNetworkConnectivity, openSpace, waterBodies, geology, landForm, builtUp, economicConnectivity, wardBoundary, agriculture, urbanGreenery, highwayData, refresh]
    );

    const [mapViewState, setMapViewState] = useState(initialViewState);

    const goToWard1 = () => {
        ward == 1 ?
            setInitialViewState({
                longitude: 85.69790102721086,
                latitude: mobile ? 27.83091528556676 : 27.82691528556676,
                zoom: mobile ? 12.2 : 12.4,
                pitch: 0,
                bearing: 0,
                transitionDuration: 1000,
                transitionInterpolator: new FlyToInterpolator()
            })
            :
            ward == 2 ?
                setInitialViewState({
                    longitude: 85.75690102721086,
                    latitude: mobile ? 27.80881528556676 : 27.80651528556676,
                    zoom: mobile ? 12.5 : 12.6,
                    pitch: 0,
                    bearing: 0,
                    transitionDuration: 1000,
                    transitionInterpolator: new FlyToInterpolator()
                })
                :
                ward == 3 ?
                    setInitialViewState({
                        longitude: 85.73290102721086,
                        latitude: mobile ? 27.81371528556676 : 27.81171528556676,
                        zoom: mobile ? 12.8 : 13,
                        pitch: 0,
                        bearing: 0,
                        transitionDuration: 1000,
                        transitionInterpolator: new FlyToInterpolator()
                    })
                    :
                    ward == 4 ?
                        setInitialViewState({
                            longitude: mobile ? 85.75390102721086 : 85.74990102721086,
                            latitude: 27.77471528556676,
                            zoom: mobile ? 12.4 : 13.4,
                            pitch: 0,
                            bearing: 0,
                            transitionDuration: 1000,
                            transitionInterpolator: new FlyToInterpolator()
                        })
                        :
                        ward == 5 ?
                            setInitialViewState({
                                longitude: mobile ? 85.72390102721086 : 85.72390102721086,
                                latitude: 27.7791528556676,
                                zoom: mobile ? 12.5 : 13,
                                pitch: 0,
                                bearing: 0,
                                transitionDuration: 1000,
                                transitionInterpolator: new FlyToInterpolator()
                            })
                            :
                            ward == 6 ?
                                setInitialViewState({
                                    longitude: mobile ? 85.70159102721086 : 85.69959102721086,
                                    latitude: 27.7831528556676,
                                    zoom: mobile ? 12.2 : 13.2,
                                    pitch: 0,
                                    bearing: 0,
                                    transitionDuration: 1000,
                                    transitionInterpolator: new FlyToInterpolator()
                                })
                                :
                                ward == 7 ?
                                    setInitialViewState({
                                        longitude: mobile ? 85.70959102721086 : 85.70859102721086,
                                        latitude: 27.7561528556676,
                                        zoom: mobile ? 11.8 : 13,
                                        pitch: 0,
                                        bearing: 0,
                                        transitionDuration: 1000,
                                        transitionInterpolator: new FlyToInterpolator()
                                    })
                                    :
                                    ward == 8 ?
                                        setInitialViewState({
                                            longitude: mobile ? 85.75050102721086 : 85.75050102721086,
                                            latitude: 27.7431528556676,
                                            zoom: mobile ? 12.17 : 12.9,
                                            pitch: 0,
                                            bearing: 0,
                                            transitionDuration: 1000,
                                            transitionInterpolator: new FlyToInterpolator()
                                        })
                                        :
                                        ward == 9 ?
                                            setInitialViewState({
                                                longitude: mobile ? 85.76550102721086 : 85.76550102721086,
                                                latitude: mobile ? 27.7301528556676 : 27.7261528556676,
                                                zoom: mobile ? 12.5 : 12.6,
                                                pitch: 0,
                                                bearing: 0,
                                                transitionDuration: 1000,
                                                transitionInterpolator: new FlyToInterpolator()
                                            })
                                            :
                                            ward == 10 ?
                                                setInitialViewState({
                                                    longitude: mobile ? 85.73450102721086 : 85.73450102721086,
                                                    latitude: mobile ? 27.7081528556676 : 27.7061528556676,
                                                    zoom: mobile ? 12.6 : 13.5,
                                                    pitch: 0,
                                                    bearing: 0,
                                                    transitionDuration: 1000,
                                                    transitionInterpolator: new FlyToInterpolator()
                                                })
                                                :
                                                ward == 11 ?
                                                    setInitialViewState({
                                                        longitude: mobile ? 85.70830102721086 : 85.70830102721086,
                                                        latitude: mobile ? 27.6731528556676 : 27.6711528556676,
                                                        zoom: mobile ? 12.2 : 12.6,
                                                        pitch: 0,
                                                        bearing: 0,
                                                        transitionDuration: 1000,
                                                        transitionInterpolator: new FlyToInterpolator()
                                                    })
                                                    :
                                                    ward == 12 ?
                                                        setInitialViewState({
                                                            longitude: mobile ? 85.71830102721086 : 85.71830102721086,
                                                            latitude: mobile ? 27.6931528556676 : 27.6951528556676,
                                                            zoom: mobile ? 12 : 13.1,
                                                            pitch: 0,
                                                            bearing: 0,
                                                            transitionDuration: 1000,
                                                            transitionInterpolator: new FlyToInterpolator()
                                                        })
                                                        :
                                                        ward == 13 ?
                                                            setInitialViewState({
                                                                longitude: mobile ? 85.70530102721086 : 85.70530102721086,
                                                                latitude: 27.7331528556676,
                                                                zoom: mobile ? 12 : 13.3,
                                                                pitch: 0,
                                                                bearing: 0,
                                                                transitionDuration: 1000,
                                                                transitionInterpolator: new FlyToInterpolator()
                                                            })
                                                            :
                                                            ward == 14 ?
                                                                setInitialViewState({
                                                                    longitude: mobile ? 85.70230102721086 : 85.70230102721086,
                                                                    latitude: 27.7131528556676,
                                                                    zoom: mobile ? 12 : 13.1,
                                                                    pitch: 0,
                                                                    bearing: 0,
                                                                    transitionDuration: 1000,
                                                                    transitionInterpolator: new FlyToInterpolator()
                                                                })
                                                                :
                                                                setInitialViewState({
                                                                    longitude: 85.72490102721086,
                                                                    latitude: mobile ? 27.76591528556676 : 27.75191528556676,
                                                                    zoom: mobile ? 10.6 : 10.78,
                                                                    pitch: 0,
                                                                    bearing: 0,
                                                                    transitionDuration: 1000,
                                                                    transitionInterpolator: new FlyToInterpolator()
                                                                })
    };

    useEffect(() => {
        goToWard1();
    }, [ward])

    const handleViewStateChange = ({ viewState }) => {
        setMapViewState(viewState);
    };

    const calculateNewXY = (viewState, longitude, latitude) => {
        const viewport = new WebMercatorViewport(viewState);
        const [x, y] = viewport.project([longitude, latitude]);
        return [x, y];
    }

    useEffect(() => {
        setPopupData();
    }, [refresh])

    useEffect(() => {
        const updatePopupPosition = () => {
            if (popupInfo) {
                const [newX, newY] = calculateNewXY(mapViewState, popupInfo.coordinate[0], popupInfo.coordinate[1]);
                const updatedPopupInfo = { ...popupInfo, x: newX, y: newY, displayData: popupData };
                setUpdatedPopup(updatedPopupInfo)
            }
        };

        updatePopupPosition();

    }, [mapViewState, popupInfo]);

    return (
        <>
            <Sidebar />
            <DeckGL
                initialViewState={initialViewState}
                controller={true}
                layers={layers}
                onViewStateChange={handleViewStateChange}
                getCursor={isDragging => {
                    return isDragging.isDragging ? 'grabbing' : (isDragging.isHovering ? 'pointer' : 'grab')
                }}
                onClick={object => {
                    !object.object && setPopupData()
                    !object.object && setInfoPanelData(null)
                    !object.object && setInfoPanel()
                    // goToWard1();
                }}
            // getTooltip={({ object }) => object && `${object.name}`}
            >
                {renderPopup()}
            </DeckGL>
            <InfoPanel info={InfoPanelData} />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                limit={1}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default Map2;
