import { IoLocationOutline, IoAddOutline, IoLogOutOutline, IoCloudUploadOutline, IoDocumentOutline, IoReloadOutline, IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import logo from "../assets/logo.png";
import useMediaQuery from "../hooks/useMediaQuery";
import axios from "axios";
import { API_BASE_URL } from "../constants/api-constants";
import { token } from "../constants/token";
import { RefreshContext } from "../App";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMobile from "../hooks/useMobile";

const Navbar = () => {
  const [addShow, setAddShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [logoutShow, setLogoutShow] = useState(false);

  const [passwordView, setPasswordView] = useState(false);

  // const [validated, setValidated] = useState(true);

  const mobile = useMobile();

  const { refresh, setRefresh } = useContext(RefreshContext);

  const toggleRefresh = () => {
    refresh === true ? setRefresh(false) : setRefresh(true)
  }

  const [loading, setLoading] = useState(false);

  const success = () => toast.success('Success!')
  const failed = () => toast.error("Error!");

  const [username, setUsername] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [role, setRole] = useState<string>()

  // const [invalidUsername, setInvalidUsername] = useState<boolean>(false);
  // const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  // const [invalidPassword, setInvalidPassword] = useState<boolean>(false);
  // const [invalidRole, setInvalidRole] = useState<boolean>(false);

  const handleAddClose = () => {
    setAddShow(false);
    setUsername("");
    setEmail("");
    setPassword("");
    setRole("");
    toggleRefresh();
  }

  const handleUploadClose = () => {
    setUploadShow(false);
    setLayer("");
    setFile(undefined);
  }

  const handleUploadShow = () => setUploadShow(true);

  const handleAddShow = () => {
    setAddShow(true);
    setValidated(true);
  }

  const handleLogoutShow = () => setLogoutShow(true);

  const handleLogoutClose = () => setLogoutShow(false);

  const mediaQ = useMediaQuery('(min-width: 860px)');
  const mediaQsm = useMediaQuery('(max-width: 340px)');

  const handleRoleChange = (event: any) => {
    setRole(event.target.value as string);
  };

  const [validated, setValidated] = useState(true);
  const [error, setError] = useState();


  const addUser = (e: any) => {
    setLoading(true);
    // username === "" || username === undefined ? setInvalidUsername(true) : setInvalidUsername(false)
    // email === "" || email === undefined || !/\S+@\S+\.\S+/.test(email) ? setInvalidEmail(true) : setInvalidEmail(false);
    // password === "" || password === undefined ? setInvalidPassword(true) : setInvalidPassword(false);
    // role === undefined || role === "" ? setInvalidRole(true) : setInvalidRole(false);
    e.preventDefault();

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };

    const url = `${API_BASE_URL}/auth/create-user`

    const data = {
      "username": username?.toLowerCase(),
      "email": email,
      "password": password,
      // "role": role
      "role": "Admin"
    }

    // invalidUsername === false && invalidEmail === false && invalidPassword === false && invalidRole === false && (
    axios.post(url, data, config)
      .then(() => {
        setLoading(false);
        handleAddClose();
        success();
      })
      .catch(err => {
        setError(err.response.status)
        setLoading(false);
        setValidated(false);
        // handleAddClose();
        // failed();
      })
    // )
  }

  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.removeItem("GISToken");
    localStorage.removeItem("GISUserRole");
    sessionStorage.removeItem("GISToken");
    sessionStorage.removeItem("GISUserRole");
    navigate(window.location.href = "/");
  }

  const hiddenFileInput = useRef<any>();

  const handleAddFile = () => {
    hiddenFileInput.current.click();
  }

  const [layer, setLayer] = useState<string>("");

  const onLayerChange = (e: any) => {
    setLayer(e.target.value)
  }

  const [file, setFile] = useState<any>();

  // const handleFileUpload = () => {

  //   const url = `${API_BASE_URL}/shapefile/upload`

  //   const config = {
  //     headers: {
  //       "Authorization": `Bearer ${token}`
  //     }
  //   };

  //   const formData = new FormData();
  //   (file !== undefined && formData !== undefined) && formData.append(layer, file[0]);

  //   if (layer === "" || file === undefined) {
  //     failed();
  //     handleUploadClose();
  //   } else {
  //     axios.post(url, formData, config)
  //       .then(res => {
  //         success();
  //         handleUploadClose();
  //       })
  //       .catch(err => {
  //         failed();
  //         handleUploadClose();
  //       })
  //   }
  // }

  return (
    <>
      <nav className="navbar"
        style={{
          padding: mobile ? "10px 15px" : "10px 50px"
        }}>
        <div className="navbar-title" style={{ gap: mediaQsm ? "5px" : "10px" }}>
          <img src={logo} width={mediaQ ? "80px" : "65px"} height={mediaQ ? "65px" : "55px"} alt="Logo" />
          {mobile ?
            <h1 style={{ margin: "0px", fontSize: mediaQsm ? "14px" : "16px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                GIS - Chautara
                <span style={{ marginTop: "-1px" }}>SangachokGadhi</span>
                <span>Municipality</span>
              </div>
              <h1 style={{ fontSize: mediaQsm ? "13px" : "15px", color: "#106eea", margin: "0px", marginTop: "3px" }}>Bagmati Province</h1>
            </h1>
            :
            <h1 style={{ margin: "0px", fontSize: `${mediaQ ? "18px" : "16px"}` }}>GIS - Chautara SangachokGadhi
              <br />Municipality
              <br /><h1 style={{ fontSize: `${mediaQ ? "17px" : "15px"}`, color: "#106eea", margin: "0px", marginTop: "3px" }}>Bagmati Province</h1>
            </h1>
          }
        </div>

        <div style={{ display: "flex", gap: `${mediaQ ? "15px" : "10px"}`, alignItems: "center", color: "#555956" }}>
          <div
            // onClick={handleUploadShow} 
            style={{ display: "flex", alignItems: "center", gap: "6px", cursor: "not-allowed", marginRight: "1px" }}>
            <IoCloudUploadOutline style={{
              width: "20px",
              height: "20px",
              marginRight: `${mediaQ ? "0px" : "-3px"}`,
              marginTop: "2px"
            }}
            />
            {mediaQ ?
              <h6 style={{ margin: "0px", marginTop: "1px" }}>Upload Shapefile</h6>
              :
              ""
            }
          </div>

          <div onClick={handleAddShow} style={{ display: "flex", alignItems: "center", gap: "2px", cursor: "pointer" }}>
            <IoAddOutline style={{
              width: "22px",
              height: "22px",
              marginRight: `${mediaQ ? "0px" : "-3px"}`
            }}
            />
            {mediaQ ?
              <h6 style={{ margin: "0px", marginTop: "1px" }}>Add User</h6>
              :
              ""
            }
          </div>

          <Link to="/map" style={{ textDecoration: "none", color: "#555956" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <IoLocationOutline style={{
                width: "20px",
                height: "20px"
              }} />
              {mediaQ ?
                <h6 style={{ margin: "0px", marginTop: "1px" }}>Map</h6>
                :
                ""
              }
            </div>
          </Link>

          <div onClick={handleLogoutShow} style={{ display: "flex", alignItems: "center", gap: "2px", cursor: "pointer" }}>
            <IoLogOutOutline style={{
              width: "20px",
              height: "20px"
            }} />
            {mediaQ ?
              <h6 style={{ margin: "0px", marginTop: "1px" }}>Logout</h6>
              :
              ""
            }
          </div>
        </div>
      </nav >

      <Modal show={addShow} onHide={handleAddClose} centered>
        <Modal.Header closeButton={loading ? false : true} style={{ padding: "15px 30px" }}>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={(e) => addUser(e)}>
          <Modal.Body style={{ padding: "15px 30px", position: "relative" }}>
            <Form.Group className="mb-3">
              <Form.Label>Username: <span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Control disabled={loading ? true : false} type="text" isInvalid={!validated} onChange={e => setUsername(e.target.value)} />
              <Form.Control.Feedback type="invalid" style={{ fontSize: "12px", marginBottom: "-5px" }}>
              </Form.Control.Feedback>
              {/* <Form.Control.Feedback type="invalid">Please enter a valid username.</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>E-mail: <span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Control disabled={loading ? true : false} isInvalid={!validated} type="email" onChange={e => setEmail(e.target.value)} />
              <Form.Control.Feedback type="invalid" style={{ fontSize: "12px", marginBottom: "-5px" }}>
              </Form.Control.Feedback>
              {/* <Form.Control.Feedback type="invalid">Please enter a valid e-mail.</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password: <span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Control disabled={loading ? true : false} isInvalid={!validated} type={passwordView ? "text" : "password"} onChange={e => setPassword(e.target.value)} />
              <Form.Control.Feedback type="invalid" style={{ fontSize: "12px", marginBottom: "-5px" }}>
                {error === 403 ? "An user with these credentials already exists." : "Please enter valid credentials."}
              </Form.Control.Feedback>
              {/* <Form.Control.Feedback type="invalid">Please enter a valid password.</Form.Control.Feedback> */}
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>Role: <span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Select disabled={loading ? true : false} defaultValue="default" onChange={handleRoleChange}>
                <option value="default" disabled>Select</option>
                <option value="Admin">Admin</option>
                <option value="BasicUser">Basic User</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please select a role.</Form.Control.Feedback>
            </Form.Group> */}

            <div onClick={() => { passwordView ? setPasswordView(false) : setPasswordView(true) }} style={{ position: "absolute", right: validated ? "40px" : "60px", bottom: validated ? "38px" : "55px", cursor: "pointer" }}>
              {!passwordView ?
                <IoEyeOutline style={{ color: "#55595d" }} />
                :
                <IoEyeOffOutline style={{ color: "#55595d" }} />
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading ? true : false} variant="secondary" onClick={handleAddClose}>
              Close
            </Button>
            <Button style={{ width: "60px" }} variant="primary" type="submit">
              {loading ?
                <Spinner size="sm" animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                :
                "Add"
              }
            </Button>
          </Modal.Footer>
        </Form>
      </Modal >

      <Modal show={uploadShow} onHide={handleUploadClose} size="lg" centered>
        <Modal.Header closeButton style={{ padding: "15px 30px" }}>
          <Modal.Title>Upload Shapefile</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px 30px" }}>
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "bold" }}>File: <span style={{ color: "red" }}>*</span></Form.Label>
            {file === undefined ?
              <div onClick={handleAddFile} style={{ display: "flex", width: "75px", alignItems: "center", cursor: "pointer", color: "white", backgroundColor: "#106eea", padding: "5px 8px 5px 2px", borderRadius: "5px" }}>
                <IoAddOutline style={{ width: "15px", height: "15px", marginBottom: "2px" }} />Upload
              </div>
              :
              <div style={{ display: "flex", alignItems: "center", fontSize: "14px", padding: "5px 8px 5px 2px" }}>
                <IoDocumentOutline style={{ marginTop: "-2px" }} />{file[0].name}<IoReloadOutline onClick={handleAddFile} style={{ marginLeft: "10px", cursor: "pointer" }} />
              </div>
            }
            <input
              type="file"
              ref={hiddenFileInput}
              accept=".zip,.rar,.7zip"
              style={{ display: "none" }}
              onChange={(event) => {
                setFile(event.target.files);
              }}
            />
            <i style={{ fontSize: "12px", color: "gray" }}>*Please select a single compressed JSON file in '.zip' format.</i>
          </Form.Group>
          <Form.Group className="mb-3" style={{ fontSize: "14px" }}>
            <Form.Label style={{ fontSize: "16px", fontWeight: "bold" }}>Layer: <span style={{ color: "red" }}>*</span></Form.Label>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <div style={{ flex: "1" }}>
                <div style={{ marginBottom: "5px" }}>
                  <span style={{ fontSize: "15px", marginRight: "8px" }}>1.</span><span style={{ fontSize: "15px" }}>Existing Urban Form</span>
                </div>
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="Agriculture" label="Land Use (Agriculture)" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="BuildUpArea" label="Build-Up Pattern" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="RoadNetworkLocalRoad" label="Internal Road Connectivity (Local Road)" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="RoadNetworkHighway" label="Internal Road Connectivity (Highway)" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="Forest" label="Urban Greenery" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="OpenSpace" label="Open Space" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="BuildingTopology" label="Building Typology" />
              </div>
              <div style={{ flex: "1" }}>
                <div style={{ marginBottom: "5px" }}>
                  <span style={{ fontSize: "15px", marginRight: "8px" }}>2.</span><span style={{ fontSize: "15px" }}>Regional Scenario and Connectivity</span>
                </div>
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="RegionalUrbanNodes" label="Regional Urban Nodes" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="HinterlandAreaAndLinkages" label="Hinterland Area and Linkages" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="RoadNetworkConnectivity" label="Road Network Connectivity (Local Road)" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="RoadNetworkConnectivity" label="Road Network Connectivity (Highway)" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="EconomicConnectivity" label="Economic Connectivity" />
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <div style={{ flex: "1" }}>
                <div style={{ marginBottom: "5px" }}>
                  <span style={{ fontSize: "15px", marginRight: "8px" }}>3.</span><span style={{ fontSize: "15px" }}>Terrain Morphology, Land Form and Hydrology</span>
                </div>
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="LandFormAndSoil" label="Land Form and Soil" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="Geology" label="Geology" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="RiverineAndLakeArea" label="River System" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="WaterBodies" label="Lakes/Ponds (Water Bodies)" />
              </div>
              <div style={{ flex: "1" }}>
                <div style={{ marginBottom: "5px" }}>
                  <span style={{ fontSize: "15px", marginRight: "8px" }}>4.</span><span style={{ fontSize: "15px" }}>Cultural and Archaeology</span>
                </div>
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="CulturalPoint" label="Existing Archaeological Sites/Monuments" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="CulturalPoint" label="Archaeological Excavation Sites" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="CulturalPoint" label="Culture Aspects (Tangible/Intangible)" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="CulturalPoint" label="Cultural and Religious Sites" />
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <div style={{ flex: "1" }}>
                <div style={{ marginBottom: "5px" }}>
                  <span style={{ fontSize: "15px", marginRight: "8px" }}>5.</span><span style={{ fontSize: "15px" }}>Public Facilities and Infrastructure</span>
                </div>
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="HealthServicesPoint" label="Health Services" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} value="EducationPoint" label="Education" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Security Services" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Administrative Services" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Utility Services" />
              </div>
              <div style={{ flex: "1" }}>
                <div style={{ marginBottom: "5px" }}>
                  <span style={{ fontSize: "15px", marginRight: "8px" }}>6.</span><span style={{ fontSize: "15px" }}>Commercial and Financial</span>
                </div>
                <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Commercial Services" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Financial Services" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Hospitality/Tourism Services" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Market Center/Bazar" />
                <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Central Business District (CBD)" />
              </div>
            </div>
            <div style={{ marginBottom: "5px" }}>
              <span style={{ fontSize: "15px", marginRight: "8px" }}>7.</span><span style={{ fontSize: "15px" }}>Socio-Economic and Demographics</span>
            </div>
            <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Population Growth Trend and Projection" />
            <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Population Distribution and Density" />
            <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Population Ethnicity" />
            <Form.Check type="radio" name="radio" onChange={onLayerChange} label="Economically Active Population" />
          </Form.Group>
          {/* <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "bold" }}>Overwrite/Append:</Form.Label>
            <Form.Select defaultValue="default">
              <option value="default" disabled>Select</option>
              <option value="overwrite">Overwrite</option>
              <option value="append">Append</option>
            </Form.Select>
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUploadClose}>
            Close
          </Button>
          <Button
            variant="primary"
          // onClick={handleFileUpload}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal >

      <Modal show={logoutShow} onHide={handleLogoutClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to logout?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLogoutClose}>
            No
          </Button>
          <Button variant="primary" onClick={() => onLogout()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={1}
      />
    </>
  );
};

export default Navbar;
