import { useContext, useEffect, useState } from "react";
import { IoEllipseOutline } from "react-icons/io5";
import { LoadingContext, MapItemsContext, RefreshContext, WardContext } from "../App";
import health from "../assets/icons/health.png";
import education from "../assets/icons/education.png";
import temple from "../assets/icons/temple.png";
import stupa from "../assets/icons/stupa.png";
import church from "../assets/icons/church.png";
import axios from "axios";
import { token } from "../constants/token";
import { API_BASE_URL } from "../constants/api-constants";

export interface Item {
  title: string;
  icon: string;
  childrens?: ChildrensEntity[] | null;
  path?: string | null;
}

export interface ChildrensEntity {
  title: string;
  icon?: string | null;
  path?: string | null;
  childrens?: ChildrensEntity1[] | null;
}

export interface ChildrensEntity1 {
  title: string;
  icon?: string | null;
  path?: string | null;
  childrens?: ChildrensEntity2[] | null;
}

export interface ChildrensEntity2 {
  title: string;
  path: string;
}

interface SideBarItemProps {
  item: Item | ChildrensEntity;
  smallFont: boolean
}

export default function SideBarItem(props: any) {

  const [open, setOpen] = useState(false);

  const { mapItems, setMapItems } = useContext(MapItemsContext);
  const { refresh, setRefresh } = useContext(RefreshContext);
  const { ward, setWard } = useContext(WardContext);

  // const [cumulativeData, setCumulativeData] = useState<any>();

  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       "Authorization": `Bearer ${token}`
  //     }
  //   };

  //   const url =
  //     ward === "all_wards"
  //       ? `${API_BASE_URL}/gis/cumulative_data`
  //       : `${API_BASE_URL}/gis/cumulative_data/${ward}`

  //   axios.get(url, config)
  //     .then(res => setCumulativeData(res.data))
  //     .catch(err => {
  //       // console.log(err);
  //     })
  // }, [ward])

  const toggleRefresh = () => {
    refresh ? setRefresh(false) : setRefresh(true);
  }

  const { loading, setLoading } = useContext(LoadingContext);

  if (props.item.sub_category) {
    return (
      <div className={open ? "sidebar-item open" : "sidebar-item"} style={{ paddingBottom: "12px" }}>
        <div className="sidebar-title" onClick={() => setOpen(!open)} style={{ cursor: loading ? "default" : "pointer" }}>
          <span>
            {props.item.icon && <i className={props.item.icon}></i>}
            <div style={{ display: "flex", gap: "5px" }}>
              <div>{props.item.id}.</div>
              <div>{props.item.name}</div>
            </div>
          </span>
          <h6 className="bi-chevron-down toggle-btn" style={{ marginBottom: '0px' }} />
        </div>
        <div className="sidebar-content">
          {props.item.sub_category.map((child: any, index: number) => {
            if (child.name === "Land Use") {
              return (
                <div style={{ marginBottom: "10px" }}>
                  <label style={{ cursor: loading ? "default" : "pointer", marginBottom: "1px", marginTop: "1px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "20px", paddingTop: "5px" }}>
                      <input
                        type="checkbox"
                        disabled={loading}
                        checked={mapItems.includes(child.name) ? true : false}
                        onChange={() => {
                          if (mapItems.includes(child.name)) {
                            const index = mapItems.indexOf(child.name)
                            mapItems.splice(index, 1)
                            toggleRefresh();
                          } else {
                            mapItems.push(child.name);
                            toggleRefresh();
                          }
                        }}
                        style={{ minWidth: "15px", minHeight: "15px", cursor: loading ? "default" : "pointer" }} />

                      <div style={{ display: "flex" }}>
                        <div style={{ minWidth: "20px", minHeight: "20px", borderRadius: "10px", backgroundColor: "rgb(255, 254, 209)", }} />
                        <div style={{ minWidth: "20px", minHeight: "20px", marginLeft: "-10px", borderRadius: "10px", backgroundColor: "rgb(165, 253, 153)", }} />
                        <div style={{ minWidth: "20px", minHeight: "20px", marginLeft: "-10px", borderRadius: "10px", backgroundColor: "rgb(255, 154, 155)", }} />
                        <div style={{ minWidth: "20px", minHeight: "20px", marginRight: "-20px", marginLeft: "-10px", borderRadius: "10px", backgroundColor: "rgb(0, 146, 251)", }} />
                      </div>
                      <SideBarItem key={index} item={child} smallFont={true} />
                    </div>
                  </label>

                  <div style={{ fontSize: "12px", marginLeft: "35px", display: "flex", flexDirection: "column", gap: "10px", marginTop: "3px" }}>
                    <div style={{ display: "flex", gap: "42px", paddingTop: "5px" }}>
                      <div style={{ width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "rgb(255, 254, 209)", marginTop: "5px" }} />
                      <div>
                        <div>Agriculture</div>
                        <div
                          style={{
                            height: "18px",
                            padding: "0px 10px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            backgroundColor: "#e0e0e0",
                            marginTop: "5px",
                            marginLeft: "-11px",
                            width: "auto",
                            display: "inline-block"
                          }}>
                          {props.cumulativeData !== undefined && props.cumulativeData.agriculture_count !== null ? `${props.cumulativeData.agriculture_count.toFixed(3)} sq. km` : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "42px" }}>
                      <div style={{ width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "rgb(165, 253, 153)", marginTop: "5px" }} />
                      <div>
                        <div>Forest</div>
                        <div
                          style={{
                            height: "18px",
                            padding: "0px 10px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            backgroundColor: "#e0e0e0",
                            marginTop: "5px",
                            marginLeft: "-11px",
                            width: "auto",
                            display: "inline-block"
                          }}>
                          {props.cumulativeData !== undefined && props.cumulativeData.forest_count !== null ? `${props.cumulativeData.forest_count.toFixed(3)} sq. km` : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "42px" }}>
                      <div style={{ width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "rgb(255, 154, 155)", marginTop: "5px" }} />
                      <div>
                        <div>Build-up Area</div>
                        <div
                          style={{
                            height: "18px",
                            padding: "0px 10px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            backgroundColor: "#e0e0e0",
                            marginTop: "5px",
                            marginLeft: "-11px",
                            width: "auto",
                            display: "inline-block"
                          }}>
                          {props.cumulativeData !== undefined && props.cumulativeData.built_up_area_count !== null ? `${props.cumulativeData.built_up_area_count.toFixed(3)} sq. m` : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "42px" }}>
                      <div style={{ width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "rgb(0, 146, 251)", marginTop: "5px" }} />
                      <div>
                        <div>Water Bodies</div>
                        <div
                          style={{
                            height: "18px",
                            padding: "0px 10px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            backgroundColor: "#e0e0e0",
                            marginTop: "5px",
                            marginLeft: "-11px",
                            width: "auto",
                            display: "inline-block"
                          }}>
                          {props.cumulativeData !== undefined && props.cumulativeData.water_bodies_count !== null ? `${props.cumulativeData.water_bodies_count.toFixed(3)} sq. m` : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            } else if (child.name === "Internal Road Connectivity" || child.name === "Road Network Connectivity") {
              return (
                <div style={{ marginBottom: "10px" }}>
                  <label style={{ cursor: loading ? "default" : "pointer", marginBottom: "1px", marginTop: "1px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                      <input
                        type="checkbox"
                        disabled={loading}
                        checked={mapItems.includes(child.name) ? true : false}
                        onChange={() => {
                          if (mapItems.includes(child.name)) {
                            const index = mapItems.indexOf(child.name)
                            mapItems.splice(index, 1)
                            toggleRefresh();
                          } else {
                            mapItems.push(child.name);
                            toggleRefresh();
                          }
                        }}
                        style={{ minWidth: "15px", minHeight: "15px", cursor: loading ? "default" : "pointer" }} />

                      <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginRight: "30px" }}>
                        <div style={{ width: "20px", height: "2px", backgroundColor: "rgb(255, 80, 44)", marginRight: "-20px" }} />
                        <div style={{ width: "20px", height: "4px", backgroundColor: "rgb(255, 80, 44)", marginRight: "-20px" }} />
                      </div>

                      <SideBarItem key={index} item={child} smallFont={true} />
                    </div>
                  </label>

                  <div style={{ fontSize: "12px", marginLeft: "35px", display: "flex", flexDirection: "column", gap: "10px", marginTop: "1px" }}>
                    <div style={{ display: "flex", gap: "62px", alignItems: "center" }}>
                      <div style={{ width: "20px", height: "2px", backgroundColor: "rgb(255, 80, 44)", marginRight: "-20px" }} />
                      <div style={{ paddingTop: "5px" }}>
                        <div>Local Road</div>
                        <div
                          style={{
                            height: "18px",
                            padding: "0px 10px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            backgroundColor: "#e0e0e0",
                            marginTop: "5px",
                            marginLeft: "-11px",
                            width: "auto",
                            display: "inline-block"
                          }}>
                          {props.cumulativeData !== undefined && props.cumulativeData.road_local_count !== null ? `${props.cumulativeData.road_local_count.toFixed(3)} km` : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "62px", alignItems: "center" }}>
                      <div style={{ width: "20px", height: "4px", backgroundColor: "rgb(255, 80, 44)", marginRight: "-20px" }} />
                      <div>
                        <div>Highway</div>
                        <div
                          style={{
                            height: "18px",
                            padding: "0px 10px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            backgroundColor: "#e0e0e0",
                            marginTop: "5px",
                            marginLeft: "-11px",
                            width: "auto",
                            display: "inline-block"
                          }}>
                          {props.cumulativeData !== undefined && props.cumulativeData.road_highway_count !== null ? `${props.cumulativeData.road_highway_count.toFixed(3)} km` : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            } else if (child.name === "Cultural and Religious Sites") {
              return (
                <div style={{ marginBottom: "10px" }}>
                  <label style={{ cursor: loading ? "default" : "pointer", marginBottom: "1px", marginTop: "1px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                      <input
                        type="checkbox"
                        disabled={loading}
                        checked={mapItems.includes(child.name) ? true : false}
                        onChange={() => {
                          if (mapItems.includes(child.name)) {
                            const index = mapItems.indexOf(child.name)
                            mapItems.splice(index, 1)
                            toggleRefresh();
                          } else {
                            mapItems.push(child.name);
                            toggleRefresh();
                          }
                        }}
                        style={{ minWidth: "15px", minHeight: "15px", cursor: loading ? "default" : "pointer" }} />

                      <div style={{ display: "flex" }}>
                        <img src={temple} width="20px" height="20px" style={{ marginRight: "-10px" }} />
                        <img src={stupa} width="20px" height="20px" style={{ marginRight: "-10px" }} />
                        <img src={church} width="20px" height="20px" style={{ marginRight: "-10px" }} />
                      </div>

                      <div>
                        <SideBarItem key={index} item={child} smallFont={true} />
                        <div
                          style={{
                            height: "18px",
                            padding: "0px 10px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            backgroundColor: "#e0e0e0",
                            marginLeft: "3px",
                            width: "auto",
                            display: "inline-block"
                          }}>
                          {props.cumulativeData !== undefined && props.cumulativeData.cultural_count !== null ? props.cumulativeData.cultural_count : "N/A"}
                        </div>
                      </div>
                    </div>
                  </label>

                  <div style={{ fontSize: "12px", marginLeft: "35px", display: "flex", flexDirection: "column", gap: "10px", marginTop: "5px" }}>
                    <div style={{ display: "flex", gap: "43px" }}>
                      <img src={temple} width="20px" height="20px" />
                      Temple
                    </div>
                    <div style={{ display: "flex", gap: "43px" }}>
                      <img src={stupa} width="20px" height="20px" />
                      Stupa
                    </div>
                    <div style={{ display: "flex", gap: "43px" }}>
                      <img src={church} width="20px" height="20px" />
                      Church
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <div>
                  <label style={{ cursor: loading ? "default" : "pointer", marginBottom: "1px", marginTop: "1px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                      <input
                        disabled={
                          loading ||
                          child.name === "Regional Urban Nodes" ||
                          child.name === "Hinterland Area and Linkages" ||
                          child.name === "Security Services" ||
                          child.name === "Administrative Services" ||
                          child.name === "Utility Services" ||
                          child.name === "Commercial Services" ||
                          child.name === "Financial Services" ||
                          child.name === "Hospitality/Tourism Services" ||
                          child.name === "Market Center/Bazar" ||
                          child.name === "Central Business District (CBD)" ||
                          child.name === "Population Growth Trend and Projection" ||
                          child.name === "Population Distribution and Density" ||
                          child.name === "Population Ethnicity" ||
                          child.name === "Economically Active Population" ||
                          child.name === "Source of Income"
                          && true}
                        type="checkbox"
                        checked={mapItems.includes(child.name) ? true : false}
                        onChange={() => {
                          if (mapItems.includes(child.name)) {
                            const index = mapItems.indexOf(child.name)
                            mapItems.splice(index, 1)
                            toggleRefresh();
                          } else {
                            mapItems.push(child.name);
                            toggleRefresh();
                          }
                        }}
                        style={{
                          minWidth: "15px",
                          minHeight: "15px",
                          cursor:
                            loading ? "default" : "pointer"
                        }} />
                      <div style={{ marginRight: "30px" }}>
                        {
                          child.name === "Health Services" ?
                            <img src={health} width="20px" height="20px" style={{ marginRight: "-18px" }} />
                            : child.name === "Education" ?
                              <img src={education} width="20px" height="20px" style={{ marginRight: "-18px" }} />
                              : child.name === "Building Typology" || child.name === "Build-Up Pattern" ?
                                <div style={{ minWidth: "20px", minHeight: "20px", marginRight: "-20px", borderRadius: "10px", backgroundColor: "rgb(255, 154, 155)", }} />
                                : child.name === "Open Space" ?
                                  <div style={{ minWidth: "20px", minHeight: "20px", marginRight: "-20px", borderRadius: "10px", backgroundColor: "rgb(255, 174, 55)", }} />
                                  : child.name === "Urban Greenery" ?
                                    <div style={{ minWidth: "20px", minHeight: "20px", marginRight: "-20px", borderRadius: "10px", backgroundColor: "rgb(165, 253, 153)", }} />
                                    : child.name === "River System" || child.name === "Lakes/Ponds (Water Bodies)" ?
                                      <div style={{ minWidth: "20px", minHeight: "20px", marginRight: "-20px", borderRadius: "10px", backgroundColor: "rgb(0, 146, 251)", }} />
                                      : child.name === "Land Form and Soil" || child.name === "Geology" ?
                                        <div style={{ minWidth: "20px", minHeight: "20px", marginRight: "-20px", borderRadius: "10px", backgroundColor: "rgb(16, 14, 285)", }} />
                                        : child.name === "Economic Connectivity" ?
                                          <div style={{ width: "20px", height: "2px", backgroundColor: "rgb(225, 58, 250)", marginRight: "-20px" }} />
                                          : child.name === "Existing Archaeological Sites/Monuments" || child.name === "Archaeological Excavation Sites" || child.name === "Culture Aspects (Tangible/Intangible)" ?
                                            <div style={{ display: "flex", marginRight: "-28px" }}>
                                              <img src={temple} width="20px" height="20px" style={{ marginRight: "-10px" }} />
                                              <img src={stupa} width="20px" height="20px" style={{ marginRight: "-10px" }} />
                                              <img src={church} width="20px" height="20px" style={{ marginRight: "-10px" }} />
                                            </div>
                                            :
                                            <IoEllipseOutline style={{ minWidth: "22px", minHeight: "22px", marginRight: "-20px" }} />
                        }
                      </div>
                      <div style={{ marginLeft: "-1px" }}>
                        <SideBarItem key={index} item={child} smallFont={true} />
                        <div
                          style={{
                            height: "18px",
                            padding: "0px 10px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            backgroundColor: "#e0e0e0",
                            marginLeft: "3px",
                            width: "auto",
                            display: "inline-block"
                          }}>
                          {child.name === "Education" ? (props.cumulativeData !== undefined && props.cumulativeData.educaiton_count !== null ? props.cumulativeData.educaiton_count : "N/A")
                            : child.name === "Health Services" ? (props.cumulativeData !== undefined && props.cumulativeData.health_count !== null ? props.cumulativeData.health_count : "N/A")
                              : child.name === "Culture Aspects (Tangible/Intangible)" || child.name === "Archaeological Excavation Sites" || child.name === "Existing Archaeological Sites/Monuments" ? (props.cumulativeData !== undefined && props.cumulativeData.cultural_count !== null ? props.cumulativeData.cultural_count : "N/A")
                                : child.name === "Economic Connectivity" ? (props.cumulativeData !== undefined && props.cumulativeData.economic_connectivity_count !== null ? `${props.cumulativeData.economic_connectivity_count.toFixed(3)} km` : "N/A")
                                  : child.name === "Building Typology" || child.name === "Build-Up Pattern" ? (props.cumulativeData !== undefined && props.cumulativeData.built_up_area_count !== null ? `${props.cumulativeData.built_up_area_count.toFixed(3)} sq. m` : "N/A")
                                    : child.name === "Open Space" ? (props.cumulativeData !== undefined && props.cumulativeData.open_space_count !== null ? `${props.cumulativeData.open_space_count.toFixed(3)} sq. m` : "N/A")
                                      : child.name === "Urban Greenery" ? (props.cumulativeData !== undefined && props.cumulativeData.forest_count !== null ? `${props.cumulativeData.forest_count.toFixed(3)} sq. km` : "N/A")
                                        : child.name === "Lakes/Ponds (Water Bodies)" || child.name === "River System" ? (props.cumulativeData !== undefined && props.cumulativeData.water_bodies_count !== null ? `${props.cumulativeData.water_bodies_count.toFixed(3)} sq. m` : "N/A")
                                          : "N/A"
                          }
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              )
            }
          }
          )}
        </div>
      </div >
    );
  } else {
    return (
      <div className={props.smallFont ? "sidebar-item smallFont" : "sidebar-item plain"}>
        {props.item.icon && (
          <img
            src={props.item.icon}
            alt=""
            className="custom-icon"
          />
        )}
        {props.item.name}
      </div>
    );
  }
}
